import { IToggleUI } from "@models/config-groups/common";
import ToggleUI from "../toggle-ui/toggle-ui.component";
import { useContext, useEffect, useState } from "react";
import { nudgeConfigContext } from "../../../../../contexts/context";
import HorizontalDiv from "../../../../../components/atoms/horizontal-div/horizontal-div.component";

interface IHeaderTag{
    config:IToggleUI,
    isLast:boolean
}

const HeaderTag:React.FunctionComponent<IHeaderTag> = ({config,isLast}) => {
    
    const [headerTag,setHeaderTag] = useState<IToggleUI>(config);
    const {setNudgeConfig} = useContext(nudgeConfigContext);
    
    useEffect(()=>{
        setNudgeConfig((prevState:any) => 
            prevState ? {
                ...prevState,
                configs:{
                    ...prevState.configs,
                    header_tag:headerTag
                }
            }:undefined
        )    
    },[headerTag,setNudgeConfig]);

    const handleHeaderToggle = () =>{
        setHeaderTag((prevState:any) => prevState 
            ? {
                ...prevState,
                enabled:!prevState.enabled
            } : undefined
        )
    }  

    return (
       <>
        <ToggleUI id={"headerTag"} config={headerTag} handleToggle={handleHeaderToggle}/>
        {
            isLast ? <></> : <HorizontalDiv></HorizontalDiv>
        }
       </>
    )
}

export default HeaderTag;