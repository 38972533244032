import React, { useContext, useState } from "react";

import Header from "src/pages/general-settings/components/molecules/header/header.component";
import { IContext, generalSettingContext } from "src/contexts/context";
import TimingBody from "./components/molecules/timing-body/timing-body.component";

const Timing: React.FunctionComponent = () => {
  const {
    generalSettings: {
      general_settings: { timing },
    },
  } = useContext(generalSettingContext) as IContext;

  const [collapse, setCollapse] = useState(true);
  const handleCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <div className="themes">
      <Header
        title={timing.name}
        description={timing.description}
        collapse={collapse}
        handleClick={handleCollapse}
      />
      {!collapse && <TimingBody />}
    </div>
  );
};

export default Timing;
