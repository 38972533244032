import { Input } from "@gofynd/nitrozen-react"
import { IAnonymous } from "@models/config-groups/anonymous-config";
import CSS from "./replace-by-word.module.scss"
import errorIcon from "../../../../../assets/icons/error_icon.svg";
import { DEBOUNCE_DELAY, debounce } from "../../../../../utils/helpers";

interface IReplaceByWordProps{
    config:IAnonymous,
    handleChange:(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const ReplaceByWord:React.FunctionComponent<IReplaceByWordProps> = ({config,handleChange}) =>{

    const debounceContentChange = debounce(handleChange, DEBOUNCE_DELAY);
    
    return (
        <div className={CSS["container"]}>
            <Input
                data-testid="input-button-text"
                onChange={debounceContentChange}
                value={config.replace_by_word.value}
                placeholder="Someone"
                maxLength={config.replace_by_word.max_char}
                label={config.replace_by_word.title}
            /> 
            {
                config.replace_by_word.value.length < config.replace_by_word.min_char && 
                <div className={CSS["error-wrapper"]}>
                    <span className={CSS['error-icon']}>
                        <img src={errorIcon} alt="error-icon"></img>
                    </span>
                    Min. {config.replace_by_word.min_char} characters required
                </div>
            }
        </div>
    )
}

export default ReplaceByWord