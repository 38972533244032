import { INudgeStats } from "../../layouts/statistics/statistics.component"
import CSS from "./statistic-chip.module.scss";
import DecreaseGrowthIcon from "../../../../../assets/icons/decrease_growth.svg";
import IncreaseGrowthIcon from "../../../../../assets/icons/increase_growth.svg"

export interface IStatisticsChipProps{
    data:INudgeStats,
    timePeriod:number | string
}

const StatisticsChip:React.FunctionComponent<IStatisticsChipProps> = ({ data,timePeriod })=>{

    const  formatCompactNumber = (value:number)=>{
        const formatter = Intl.NumberFormat("en", { notation: "compact" });
        return formatter.format(value);
    }

    return <div className={CSS['container']}>
        <p className={CSS['label']}>{data.label}</p>
        <div className={CSS['stats-wrapper']}>
            <span className={CSS['value']}>
                {formatCompactNumber(data.value)}
            </span>
            {
                timePeriod === "ALL" ? <></> : (
                    <>
                        <span className={CSS['growth-icon']}>
                            <img src={data.growth_type === "INCREASE" ? IncreaseGrowthIcon : DecreaseGrowthIcon} alt="growth-icon"></img>
                        </span>
                        <span className={`${CSS['growth-percentage']} ${data.growth_type === "INCREASE" ? CSS['increase']: CSS['decrease']}`}>
                            {data.percentage_change}%
                        </span>
                    </>
                )
            }
        </div>
    </div>
}


export default StatisticsChip