import { ICartSummaryCondition } from "@models/config-groups/cart-summary-condition-config";
import CSS from "./cart-summary-condition.module.scss";
import MinInput from "../../atoms/min-input/min-input.component";
import { useContext, useEffect, useState } from "react";
import { nudgeConfigContext } from "src/contexts/context";
import { Dropdown } from "@gofynd/nitrozen-react";
import HorizontalDiv from "../../../../../components/atoms/horizontal-div/horizontal-div.component";
import { isInputValueWithinRange } from "../../../../../utils/helpers";

interface ICartSummaryConditionProps{
    config:ICartSummaryCondition,
    isLast:boolean,
    setIsBtnDisabled:Function
}

const CartSummaryCondition:React.FunctionComponent<ICartSummaryConditionProps> = ({config,isLast,setIsBtnDisabled})=>{
    const [cartSummaryCondition,setCartSummaryCondition] = useState<ICartSummaryCondition>(config);
    const {setNudgeConfig} = useContext(nudgeConfigContext);

    useEffect(()=>{
        if(isInputValueWithinRange(
            cartSummaryCondition.min_items.value,
            cartSummaryCondition.min_items.min,
            cartSummaryCondition.min_items.max) 
            && 
            isInputValueWithinRange(
                cartSummaryCondition.min_visitors.value,
                cartSummaryCondition.min_visitors.min,
                cartSummaryCondition.min_visitors.max
            )){
            setNudgeConfig((prevState:any) => 
                prevState ? {
                    ...prevState,
                    configs:{
                        ...prevState.configs,
                        cart_summary_condition:cartSummaryCondition
                    }
                }:undefined
            ) 
            setIsBtnDisabled(false);
        }   
    },[cartSummaryCondition,setNudgeConfig,setIsBtnDisabled]);
    
    const handleMinVisitorChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setCartSummaryCondition((prevState:any)=> prevState ? {
            ...prevState,
            min_visitors:{
                ...prevState.min_visitors,
                value:Number(event?.target?.value)
            }
        }:undefined);
    }

    const handleMinItemsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setCartSummaryCondition((prevState:any)=> prevState ? {
            ...prevState,
            min_items:{
                ...prevState.min_items,
                value: Number(event?.target?.value)
            }
        }:undefined);
    }

    const handleTimePeriodChange = (value:string) => {
        setCartSummaryCondition((prevState:any) => prevState ? {
            ...prevState,
            time_period:{
                ...prevState.time_period,
                value:Number(value)
            }
        }:undefined)
    }
    return (
        <>
            <div className={CSS['container']}>
                <div className={CSS['grid-of-two']}>
                    <MinInput 
                        config={cartSummaryCondition.min_visitors} 
                        handleOnChange={handleMinVisitorChange}
                        setIsBtnDisabled={setIsBtnDisabled}
                    />
                    <MinInput 
                        config={cartSummaryCondition.min_items} 
                        handleOnChange={handleMinItemsChange}
                        setIsBtnDisabled={setIsBtnDisabled}
                    />
                </div>
                <div>
                    <Dropdown 
                        items={cartSummaryCondition.time_period.items}
                        label={cartSummaryCondition.time_period.title}
                        onChange={handleTimePeriodChange}
                        tooltip={cartSummaryCondition.time_period.tooltip}
                        tooltipPosition="top-start"
                        value={cartSummaryCondition.time_period.value}
                    />
                </div>
            </div>
            {
                isLast ? <></> : <HorizontalDiv></HorizontalDiv>
            }
        </>
    )
}

export default CartSummaryCondition;