import { Checkbox } from "@gofynd/nitrozen-react";
import CSS from "./nudge-list-item.module.scss";
import deleteBtn from "../../../../../assets/icons/delete_btn.svg";
import { formatDateTime, getNudgeTypeDisplayName } from "src/utils/helpers";
import { createNudge } from "../../../utils/nudge-data-utility";

interface INudgeListItemProps{
    data:any,
    selectedNudgeId:Array<any>,
    handleOnSelect:Function,
    handleDelete:Function,
}

const NudgeListItem:React.FunctionComponent<INudgeListItemProps> = ({
    data,
    selectedNudgeId,
    handleOnSelect,
    handleDelete,
})=>{

    const getCheckboxValue = ()=>{
        if(selectedNudgeId.includes(data._id))
            return true;
        return false;
    }

    const getNudgeObj = (nudgeData:any)=>{
        return createNudge(nudgeData,"#41434C");
    }

    return <div className={CSS['container']}>
            <div className={CSS['checkbox-container']}>
                <Checkbox
                    name="table-checkout-item"
                    id={data._id}
                    checkboxValue={getCheckboxValue()}
                    onChange={(value:boolean)=> handleOnSelect(value,data._id)}
                />
            </div>
            <div className={CSS['nudge-ui-container']}>
                <div className={CSS['icon-img-container']}>
                    <img src={getNudgeObj(data).icon} alt="nudge-icon"></img>
                </div>
                <div className={CSS['nudge-text-container']}>
                    <p dangerouslySetInnerHTML={{ __html: getNudgeObj(data).content }}>
                    </p>
                </div>
            </div>
            <div className={`${CSS['column-text']} ${CSS['capitalize-text']}`}>{getNudgeTypeDisplayName(data?.type)}</div>
            <div className={CSS['column-text']}>{formatDateTime(data?.updated_at)}</div>
            <div className={CSS['action-container']}>
                <div className={CSS['delete-btn-container']} onClick={()=>handleDelete(data._id)}>
                    <img src={deleteBtn} alt="delete-btn"></img>
                </div>  
            </div>
    </div>
}

export default NudgeListItem