import { ToastManager } from "@gofynd/nitrozen-react";
import {  TOP_CENTER } from "../constants/toastConstant";

const toast = new ToastManager(TOP_CENTER);

type ShowToast = (title: string, handleDestroyToaster?: () => void) => void;

export const showSuccessToast: ShowToast = (title, handleDestroyToaster = () => undefined) => {
    toast.show({
        destroy: handleDestroyToaster,
        title: title,
        position:TOP_CENTER,
        leftIconPosition: "",
        rightIconPosition: "",
        leftImage: null,
        rightImage: null,
        content:undefined,
        shouldClose:false,
        duration:2000
    });
}

export const showDangerToast: ShowToast = (title, handleDestroyToaster = () => undefined) => {
    toast.show({
        destroy: handleDestroyToaster,
        title: title,
        position:TOP_CENTER,
        leftIconPosition: "",
        rightIconPosition: "",
        leftImage: null,
        rightImage: null,
        content:undefined,
        shouldClose:false,
        duration:2000
    })
}

