import axios from "../../../services/http.service";
import { ApplicationPage } from "@models/application-page";

export const toggleExtensionActive = (
  companyId: string,
  applicationId: string,
  active: boolean
) => {
  return axios.put(`/platform/api/v1/application/${applicationId}`, {
    extension_active: active,
    company_id: companyId,
  });
};


export const getAllPagesForSalesChannel = async (id: string) => {
  return axios.get<ApplicationPage[]>(`/platform/api/v1/application/pages/${id}`);
};