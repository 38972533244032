import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CSS from "./pop-categories.module.scss";
import accordianCollapse from "../../../../assets/icons/icon_accordian_collapse.svg";
import accordianExpand from "../../../../assets/icons/icon_accordian_expand.svg";
import Card from "src/pages/features/components/atoms/card.component";
import { getIconForNudgeType } from "src/utils/helpers";

interface IPopCategory {
  type: string;
  name: string;
  description: string;
  items: Array<{
    type: string;
    name: string;
    iconSrc: string;
    description: string;
    comingSoon?:boolean;
  }>;
}

interface IPopCategoriesProps {
  popCategory: IPopCategory;
  show: boolean;
  handleShow: Function;
  nudgeActiveStatus:any;
}

const PopCategories: React.FunctionComponent<IPopCategoriesProps> = ({
  popCategory,
  show,
  handleShow,
  nudgeActiveStatus
}) => {

  const navigate = useNavigate();
  const { company_id: companyId, application_id: applicationId } = useParams();
  
  const handleFeatureClick = (nudgeType:string) =>{
    navigate(`/company/${companyId}/application/${applicationId}/nudge-config/?nudgeType=${nudgeType}`);
  }

  return (
    <div className={CSS["pop-Category"]}>
      <div
        className={CSS["category-content"]}
        onClick={() => handleShow(popCategory.type)}
      >
        <div>
          <div className={CSS["header"]}>{popCategory.name}</div>
          <div className={CSS["description"]}>{popCategory.description}</div>
        </div>
        <div>
          {show ? (
            <img src={accordianCollapse} alt=""/>
          ) : (
            <img src={accordianExpand} alt=""/>
          )}
        </div>
      </div>
      {show && (
        <div>
          <div className={CSS["categories"]}>
            {popCategory.items.map((pop, idx) => (
              <Card
                id={pop.type}
                title={pop.name}
                iconSrc={getIconForNudgeType(pop.type)}
                description={pop.description}
                handleClick={handleFeatureClick}
                showActive={true}
                active={nudgeActiveStatus[pop.type]}
                comingSoon={!!pop.comingSoon}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopCategories;
