import { useState } from "react";
import NudgeEventLibrary from "./component/layouts/nudge-event-library/nudge-event-library.component";
import Statistics from "./component/layouts/statistics/statistics.component";
import CSS from "./dashboard.module.scss";
import { Dropdown } from "@gofynd/nitrozen-react";

const TIME_PERIOD_DROPDOWN = [
    {
        text:"ALL",
        value:"ALL"
    },
    {
        text:"LAST DAY",
        value:1
    },
    {
        text:"LAST 15 Days",
        value: 15
    },
    {
        text:"LAST 30 Days",
        value: 30
    }
]

const Dashboard: React.FunctionComponent = () =>{

    const [selectedTimePeriod,setSelectedTimePeriod] = useState<any>("ALL");

    const handleDropdownChange = (value:number) =>{
        setSelectedTimePeriod(value);
    }

    return <div className={CSS['container']}>
        <div className={CSS["header-container"]}>
            <div className={CSS['page-header']}>
                <p className={CSS['heading']}>Dashboard</p>
                <p className={CSS['desc']}>Track and analyze valuable data of smart nudge</p>
            </div>
            <div className={CSS['dropdown']}>
                <Dropdown
                    items={TIME_PERIOD_DROPDOWN} 
                    placeholder="SELECT TIME PERIOD"
                    value={selectedTimePeriod} 
                    onChange={handleDropdownChange}
                    className={CSS['dropdownClass']}
                />
            </div>   
        </div>
        <Statistics timePeriod={selectedTimePeriod}/>
        <NudgeEventLibrary />
    </div>
}


export default Dashboard;