import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { updateSubscriptionStatus } from "../../../services/subscription.service";
import { showDangerToast, showSuccessToast } from "src/services/toast.service";

export const SubscriptionStatus = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { company_id } = useParams();
  const platformSubscriptionId = searchParams.get("subscription_id");

  useEffect(() => {
    if (!platformSubscriptionId) {
      navigate(`/company/${company_id}/payment`);
      return;
    }

    (async () => {
      try {
        const res = await updateSubscriptionStatus(platformSubscriptionId, {
          company_id,
        });
        if (res.status === 200) {
          if (res?.data?.results.status === "active") {
            navigate(`/company/${company_id}`);
            showSuccessToast("SUCCESSFULLY UPDATED SUBSCRIPTION")
          } else {
            navigate(`/company/${company_id}/payment`);
          }
        }else{
          showDangerToast("ERROR WHILE UPDATING SUBSCRIPTION")
        }
      } catch (ex) {
        showDangerToast("ERROR WHILE UPDATING SUBSCRIPTION")
        navigate(`/company/${company_id}/payment`);
        console.log(ex);
      }
    })();
  }, []);

  return <></>;
};
