import React from "react";

interface IHorizontalDiv {
  color?: string;
  height?: number;
  width?: number;
}

const HorizontalDiv: React.FunctionComponent<IHorizontalDiv> = ({
  color = "#EDECE9",
  height = 1,
  width = 100,
}) => {
  return (
    <div
      data-testid="horizontal-div"
      style={{
        height: `${height}px`,
        background: `${color}`,
        width: `${width}%`,
      }}
    ></div>
  );
};

export default HorizontalDiv;
