import { Input } from "@gofynd/nitrozen-react";
import { IMinValue } from "@models/config-groups/common";
import { DEBOUNCE_DELAY, debounce } from "../../../../../utils/helpers";
import { useEffect, useState } from "react";
import { Tooltip } from "@gofynd/nitrozen-react";
import toolTipIcon from "../../../../../assets/icons/icon_tooltip.svg";
import CSS from "./min-input.module.scss";

interface IMinValueProps{
    config:IMinValue,
    handleOnChange:(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    setIsBtnDisabled:Function
}

const MinInput:React.FunctionComponent<IMinValueProps> = ({config,handleOnChange,setIsBtnDisabled}) =>{
    
    const debounceContentChange = debounce(handleOnChange, DEBOUNCE_DELAY);
    const [isError,setIsError] = useState<boolean>(false);

    useEffect(()=>{
        if(Number(config.value) < config.min || Number(config.value) > config.max){
            setIsBtnDisabled(true);
            setIsError(true);
            return;
        }
        setIsError(false);
    },[config,setIsBtnDisabled]);

    return (
        <>
            <div className={CSS['min-input-container']}>
                <div className={CSS['title-container']}>
                    <label className={CSS['label']}>{config.title}</label>
                    <Tooltip
                        tooltipContent={config.tooltip}
                        icon={<img src={toolTipIcon} alt="tooltip"/>}
                        position="top-start"
                    />
                </div>
                <Input
                    type="number"
                    onChange={debounceContentChange}
                    value={config.value}
                    // label={config.title}
                    showTooltip={true}
                    tooltipText={config.tooltip}
                    min={config.min}
                    max={config.max}
                    state={isError ? "error":"default"}
                    stateText={isError ? `Input Value Should be Between ${config.min} and ${config.max}` : ""}
                /> 
            </div>
        </>
    )
}

export default MinInput;