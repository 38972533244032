import React, { useContext } from "react";

import CSS from "./themes-body.module.scss";
import { IContext, generalSettingContext } from "src/contexts/context";
import { Radio } from "@gofynd/nitrozen-react";

const ThemesBody: React.FunctionComponent = () => {
  const {
    generalSettings: {
      general_settings: { themes },
    },
    setGeneralSettings,
  } = useContext(generalSettingContext) as IContext;

  const { items: themesOptions } = themes;

  const handleThemesRadio = (value: string) => {
    setGeneralSettings((prevState:any) =>
      prevState
        ? {
            ...prevState,
            general_settings: {
              ...prevState.general_settings,
              themes: {
                ...prevState.general_settings.themes,
                value: value,
              },
            },
          }
        : undefined
    );
  };

  return (
    <div className={CSS["body"]}>
      {Object.keys(themesOptions).map((key) => (
        <Radio
          id={themesOptions[key].type}
          labelText={themesOptions[key].name}
          name="themes-radio"
          onChange={() => handleThemesRadio(themesOptions[key].type)}
          radioValue={themes.value}
          value={themesOptions[key].type}
          key={themesOptions[key].type}
          className={CSS["themes-option"]}
        />
      ))}
    </div>
  );
};

export default ThemesBody;
