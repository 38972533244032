import { Button } from "@gofynd/nitrozen-react";
import CSS from "./pricing-card.module.scss";
import { useParams } from "react-router-dom";
import { subscribeToPlan } from "../../../services/subscription.service";

const PricingCard = ({ plan }:any) => {
  const { company_id } = useParams();

  const handleSelectPlan = async () => {
    try {
      const res = await subscribeToPlan(plan?._id, { company_id });
      if (res.status === 200) {
        window.location.href = res?.data?.results.confirm_url;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={CSS["card"]} key={plan?._id}>
      <div className={CSS["card_heading"]}>{plan?.displayName}</div>
      <div className={CSS["card_tagline"]}>{plan?.tagline}</div>
      <div className={CSS["card_text"]}>
        <span className={CSS["card_pricing"]}>{"₹" + plan?.price?.amount}</span>/
        {plan?.price?.frequency?.toLowerCase()}
      </div>
      <div className={CSS["card_details_list"]}>
        {plan?.features?.map((feature:any, index:any) => {
          return (
            <div key={feature}>
              <div className={CSS["card_text"]}>{feature}</div>
              {index !== plan?.features?.length - 1 && (
                <div className={`${CSS["card_text"]} ${CSS["mt_10"]}`}>+</div>
              )}
            </div>
          );
        })}
      </div>
      <div className={CSS["card_button_wrapper"]}>
        <Button
          theme="primary"
          onClick={handleSelectPlan}
          className={CSS["card_button"]}
        >
          Select Plan
        </Button>
      </div>
    </div>
  );
};

export default PricingCard;
