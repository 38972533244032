import HorizontalDiv from "../../../../../components/atoms/horizontal-div/horizontal-div.component";
import CSS from "./low-stock-condition.module.scss"
import { useContext, useEffect, useState } from "react";
import { nudgeConfigContext } from "src/contexts/context";
import { ILowStockCondition } from "@models/config-groups/low-stock-condition-config";
import MinInput from "../../atoms/min-input/min-input.component";
import ExcludedProducts from "../excluded-products/excluded-products.component";
import { isInputValueWithinRange } from "../../../../../utils/helpers";

interface ILowStockConditionProps{
    config:ILowStockCondition,
    isLast:boolean,
    setIsBtnDisabled:Function
}

const LowStockCondition:React.FunctionComponent<ILowStockConditionProps> = ({config,isLast,setIsBtnDisabled}) =>{
    const [lowStockCondition,setLowStockCondition] = useState<ILowStockCondition>(config);
    const {setNudgeConfig} = useContext(nudgeConfigContext);
    
    useEffect(()=>{
        if(isInputValueWithinRange(
            lowStockCondition.min_inventory.value,
            lowStockCondition.min_inventory.min,
            lowStockCondition.min_inventory.max) 
        ){
            setNudgeConfig((prevState:any) => 
                prevState ? {
                    ...prevState,
                    configs:{
                        ...prevState.configs,
                        low_stock_condition:lowStockCondition
                    }
                }:undefined
            );
            setIsBtnDisabled(false);
        }
    },[lowStockCondition,setNudgeConfig,setIsBtnDisabled]);


    const handleMinInventoryChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setLowStockCondition((prevState:any)=> prevState ? {
            ...prevState,
            min_inventory:{
                ...prevState.min_inventory,
                value:Number(event?.target?.value)
            }
        }:undefined);
    }

    const handleExcludedProductsChange = (products:any)=>{
        setLowStockCondition((prevState:any)=> prevState ? {
            ...prevState,
            excluded_products:{
                ...prevState.excluded_products,
                items:products
            }
        }:undefined);
    }

    return (
        <>
            <div className={CSS['container']}>
                <div className={CSS['min-input-container']}>
                    <MinInput 
                        config={lowStockCondition.min_inventory} 
                        handleOnChange={handleMinInventoryChange}
                        setIsBtnDisabled={setIsBtnDisabled}
                    />
                </div>
                <ExcludedProducts 
                    config={lowStockCondition.excluded_products} 
                    handleOnChange={handleExcludedProductsChange} 
                />
            </div>
            {
                isLast ? <></> : <HorizontalDiv></HorizontalDiv>
            }
        </>
    )
}


export default LowStockCondition