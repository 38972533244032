import React, { ChangeEvent, ChangeEventHandler } from "react";

import { Tooltip } from "@gofynd/nitrozen-react";
import Slider from "src/components/molecules/slider/slider.component";
import toolTipIcon from "../../../../../../../../../../assets/icons/icon_tooltip.svg";
import CSS from "./timing-body-item.module.scss";

interface ITimingBodyItem {
  type: string;
  title: string;
  tool_tip?: string | undefined;
  range: {
    min: number;
    max: number;
    step: number;
  };
  value: number;
  suffix?: string;
  handleTimingSlider: Function;
}

const TimingBodyItem: React.FunctionComponent<ITimingBodyItem> = ({
  type,
  range,
  title,
  value,
  tool_tip,
  suffix = "",
  handleTimingSlider,
}) => {
  return (
    <div className={CSS["timing-body-item"]}>
      <div className={CSS["header"]}>
        <div className={CSS["title"]}>{title}</div>
        {tool_tip && (
          <Tooltip
            className={CSS["tooltip"]}
            tooltipContent={tool_tip}
            position="top-start"
            icon={<img src={toolTipIcon} alt="tooltip"/>}
          />
        )}
      </div>
      <Slider
        min={range?.min}
        max={range?.max}
        step={range?.step}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          handleTimingSlider(
            type.toLocaleLowerCase(),
            parseInt(event.target.value)
          )
        }
        defaultValue={value}
        optionSuffix={suffix}
        style={{ width: "50%" }}
        key={type}
      />
    </div>
  );
};

export default TimingBodyItem;
