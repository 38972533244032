import React, { useContext, useEffect, useState } from "react";
import CSS from "./settings-preview.module.scss";
import LivePreview from "src/components/molecules/live-preview/live-preview.component";
import { generalSettingContext } from "src/contexts/context";
import { INudgeSettings } from "src/pages/general-settings/general-settings.types";

interface IDataToSend{
  generalConfig:INudgeSettings
}

const SettingsPreview: React.FunctionComponent = () => {
  const { generalSettings } = useContext(generalSettingContext);
  const [ dataToSend , setDataToSend] = useState<IDataToSend>({} as IDataToSend);

  useEffect(()=>{
      setDataToSend({
          generalConfig:generalSettings
      })
  },[generalSettings]);

  return (
      <div className={CSS["container"]}>
          <LivePreview dataToSend={dataToSend}/>
      </div>
  )
};

export default SettingsPreview;
