import { Checkbox } from "@gofynd/nitrozen-react";
import CSS from "./nudge-table.module.scss"
import NudgeListItem from "../../molecules/nudge-list-item/nudge-list-item.component";

interface INudgeTableProps{
    tableData:any,
    handleOnAllSelect:Function,
    selectedNudgeId:Array<any>,
    handleOnSelect:Function,
    handleDelete:Function,
    shouldDeleteAll:any,
}

const NudgeTable:React.FunctionComponent<INudgeTableProps> = ({
    tableData,
    handleOnAllSelect,
    selectedNudgeId,
    handleOnSelect,
    handleDelete,
    shouldDeleteAll
})=>{
    
    return <div className={CSS['table-container']}>
        <div className={CSS['table-header']}>
            <div className={CSS['checkbox-container']}>
                <Checkbox
                    name="Delete-all-checkbox"
                    id={'should-delete-all-checkbox'}
                    onChange={handleOnAllSelect}
                    checkboxValue={shouldDeleteAll}
                />
            </div>
            <div>Content</div>
            <div>Type</div>
            <div>Date and Time</div>
            <div>Action</div>
        </div>
        <div>
            {
                tableData.map((nudgeData:any)=>{
                    return <NudgeListItem 
                        data={nudgeData} 
                        selectedNudgeId={selectedNudgeId} 
                        handleOnSelect={handleOnSelect}
                        handleDelete={handleDelete}
                    />
                })
            }
        </div>
    </div>
}

export default NudgeTable;