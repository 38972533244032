import { IToggleUI } from "@models/config-groups/common";
import ToggleUI from "../toggle-ui/toggle-ui.component";
import { useContext, useEffect, useState } from "react";
import { nudgeConfigContext } from "../../../../../contexts/context";
import HorizontalDiv from "../../../../../components/atoms/horizontal-div/horizontal-div.component";

interface INotificationTime{
    config:IToggleUI,
    isLast:boolean
}

const NotificationTime:React.FunctionComponent<INotificationTime> = ({config,isLast}) => {
    
    const [notificationTime,setNotificationTime] = useState<IToggleUI>(config);
    const {setNudgeConfig} = useContext(nudgeConfigContext);
    
    useEffect(()=>{
        setNudgeConfig((prevState:any) => 
            prevState ? {
                ...prevState,
                configs:{
                    ...prevState.configs,
                    notification_time:notificationTime
                }
            }:undefined
        )    
    },[notificationTime,setNudgeConfig]);

    const handleNotificationToggle = () =>{
        setNotificationTime((prevState:any) => prevState 
            ? {
                ...prevState,
                enabled:!prevState.enabled
            } : undefined
        )
    }  

    return (
       <>
        <ToggleUI id={"notificationTime"} config={notificationTime} handleToggle={handleNotificationToggle}/>
        {
            isLast ? <></> : <HorizontalDiv></HorizontalDiv>
        }
       </>
    )
}

export default NotificationTime;