import CSS from "./alert-dialog.module.scss"; 
import alertDialogSvg  from "../../../../../assets/icons/alert_dialog.svg";
import { Button } from "@gofynd/nitrozen-react";

interface IAlertDialogProps{
    handleCancelClick:any,
    handleConfirmClick:any,
    modalState:any
}

const AlertDialog:React.FunctionComponent<IAlertDialogProps> = ({handleCancelClick,handleConfirmClick,modalState})=>{
    return (
        <div className={CSS['dialog-backdrop']}>
            <div className={CSS['dialog-body']}>
                <img src={alertDialogSvg} alt="alert-dialog-svg"></img>
                <p className={CSS['heading']}>Are you sure?</p>
                <p className={CSS['subheading']}>{modalState.message}</p>
                <div className={CSS['btn-container']}>
                    <Button 
                        theme="secondary" 
                        size="medium" 
                        fullWidth={true}
                        onClick={handleCancelClick}
                    >
                        No
                    </Button>
                    <Button 
                        theme="primary" 
                        size="medium" 
                        fullWidth={true}
                        onClick={()=> handleConfirmClick(modalState.type)}
                    >
                        Yes
                    </Button>
                </div>
            </div>
        </div>
    )
}


export default AlertDialog;