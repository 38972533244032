let companyId: string | null = null;
let applicationId: string | null = null;

export const getCompanyIdFromSearchParam = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("company_id");
};

export const setCompanyIdFromSearchParam = () => {
  const company_id = getCompanyIdFromSearchParam();
  if (company_id) {
    companyId = company_id;
    return true;
  }
  return false;
};

export const setCompanyId = (company_id: string) => {
  companyId = company_id;
};

export const getCompanyId = () => {
  return companyId;
};

export const setApplicationId = (application_id: string) => {
  if (application_id) {
    applicationId = application_id;
    return true;
  }
  return false;
};

export const getApplicationId = () => {
  return applicationId;
};
