import React, { useContext } from "react";

import CSS from "./devices.module.scss";
import { IContext, generalSettingContext } from "src/contexts/context";
import { Checkbox } from "@gofynd/nitrozen-react";

const DevicesBody: React.FunctionComponent = () => {
  const {
    generalSettings: {
      general_settings: {
        devices: { items: devices },
      },
    },
    setGeneralSettings,
  } = useContext(generalSettingContext) as IContext;

  const handleDevicesCheckbox = (type: string) => {
    setGeneralSettings((prevState:any) =>
      prevState
        ? {
            ...prevState,
            general_settings: {
              ...prevState.general_settings,
              devices: {
                ...prevState.general_settings.devices,
                items: {
                  ...prevState.general_settings.devices.items,
                  [type.toLocaleLowerCase()]: {
                    ...prevState.general_settings.devices.items[
                      type.toLocaleLowerCase()
                    ],
                    selected:
                      !prevState.general_settings.devices.items[
                        type.toLocaleLowerCase()
                      ].selected,
                  },
                },
              },
            },
          }
        : undefined
    );
  };
  return (
    <div className={CSS["body"]}>
      {Object.keys(devices).map((key) => (
        <Checkbox
          className={CSS["checkbox"]}
          name="device-checkbox"
          id={devices[key].name}
          labelText={devices[key].name}
          value={devices[key].type}
          checkboxValue={devices[key].selected}
          onChange={() => handleDevicesCheckbox(devices[key].type)}
          key={devices[key].type}
        />
      ))}
    </div>
  );
};

export default DevicesBody;
