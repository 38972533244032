import { IPalette } from '@models/config-groups/highlight-color';
import ColorSelector from '../../atoms/color-selector/color-selector.component';
import CSS from './color-palette.module.scss'

interface IColorPalette{
    colorPalette:IPalette,
    handleSelect:Function,
    selectedColorBorder:string
}

const ColorPalette:React.FunctionComponent<IColorPalette> = ({colorPalette,handleSelect,selectedColorBorder}) =>{
    
    return (
        <div className={CSS["container"]}>   
            {
                colorPalette.items.map((color,index)=>{
                    return <ColorSelector 
                                color={color} 
                                handleSelect={handleSelect} 
                                selected={color === colorPalette.value ? true : false}
                                selectedColorBorder={selectedColorBorder}
                            />
                })
            }
           
        </div>
    )
}

export default ColorPalette;