import CSS from "./color-selector.module.scss"

interface IColor{
    color: string,
    handleSelect:Function,
    selected:boolean,
    selectedColorBorder:string
}

const ColorSelector :React.FunctionComponent<IColor>= ({color,handleSelect,selected,selectedColorBorder}) =>{
    return (
        <div 
            className={CSS["outer-circle"]} 
            style={selected ? {border:selectedColorBorder}: {border:"1px solid #DBDBDB"}}>
            <div 
                className={CSS['inner-circle']} 
                style={{backgroundColor:color}} 
                onClick={()=> handleSelect(color)}
            ></div>
        </div>
    )
}


export default ColorSelector;