import axios from "../../../services/http.service";
import { INudgeSettings } from "../general-settings.types";

export const getGeneralSettings = async (applicationId: string) => {
  return axios.get(`/platform/api/v1/general-settings/${applicationId}`);
};

export const createDefaultSettings = async (applicationId: string) => {
  return axios.post(`/platform/api/v1/general-settings/${applicationId}`);
};

export const updateNudgeSettings = async (
  applicationId: string,
  settings: INudgeSettings
) => {
  return axios.put(`/platform/api/v1/general-settings/${applicationId}`, {
    data: settings,
  });
};
