import React, { useContext, useState } from "react";

import Header from "src/pages/general-settings/components/molecules/header/header.component";
import { IContext, generalSettingContext } from "src/contexts/context";
import DevicesBody from "./components/molecules/devices-body/devices-body.component";

const Devices: React.FunctionComponent = () => {
  const {
    generalSettings: {
      general_settings: { devices },
    },
  } = useContext(generalSettingContext) as IContext;

  const [collapse, setCollapse] = useState(true);
  const handleCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <div className="devices">
      <Header
        title={devices.name}
        description={devices.description}
        collapse={collapse}
        handleClick={handleCollapse}
      />
      {!collapse && <DevicesBody />}
    </div>
  );
};

export default Devices;
