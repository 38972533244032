import { Dispatch, SetStateAction, createContext } from "react";
import { INudgeSettings } from "../pages/general-settings/general-settings.types";
import { INudgeConfig } from "../pages/nudge-settings/nudge-setting.types";

// General Settings Page Context
export interface IContext {
  generalSettings: INudgeSettings;
  setGeneralSettings: Dispatch<SetStateAction<INudgeSettings>>;
}

export const generalSettingContext = createContext<IContext>({} as IContext);

// Nudge Config Page Context
export interface IConfigContext{
  nudgeConfig: INudgeConfig;
  setNudgeConfig: Dispatch<SetStateAction<INudgeConfig>>;
}

export const nudgeConfigContext = createContext<IConfigContext>({} as IConfigContext);
