import { getSubscriptionStatus } from "../../../services/subscription.service";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TOAST_MESSAGES } from "src/constants/toastConstant";
import { showDangerToast } from "src/services/toast.service";

interface IRouteWrapperProps {
  children: JSX.Element;
}


export function RouteWrapper({ children }:IRouteWrapperProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { company_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const res = await getSubscriptionStatus({ company_id });

        if(res.status === 200){
          if (res.data?.results?.subscription && res.data?.results?.subscription.status === "active") {
            setIsLoading(false);
          }
          else {
            showDangerToast(res.data.message); 
            navigate(`/company/${company_id}/payment`);
          }
        }
      } catch (ex) {
        showDangerToast(TOAST_MESSAGES.subscriptionError);
      }
    })();
  }, []);

  return <>{!isLoading && children}</>;
}
