import  { memo } from "react";
import { ToggleButton } from "@gofynd/nitrozen-react";
import CSS from "./toggle.module.scss";

interface InputTextAtomProps {
  id: string;
  value: boolean;
  onToggle: Function;
  size?: string;
}

function Toggle({
  id,
  value,
  onToggle,
  size = "small",
}: InputTextAtomProps): JSX.Element {

  return (
    <div className={CSS["toggle-wrapper"]}>
      <ToggleButton
        data-testid={"toggle" + id}
        id={id}
        onToggle={onToggle}
        size={size}
        value={value}
      />
    </div>
  );
}

const ToggleAtom = memo(Toggle);
export default ToggleAtom;
