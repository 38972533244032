import { useState } from "react";
import { generalSettingContext } from "./contexts/context";
import Router from "./routes/router";
import { INudgeSettings } from "./pages/general-settings/general-settings.types";

const App = () =>{
    const [generalSettings, setGeneralSettings] = useState<INudgeSettings>({} as INudgeSettings);
    
    return (
        <generalSettingContext.Provider value={{ 
            generalSettings: generalSettings,
            setGeneralSettings: setGeneralSettings,
        }}>
            <Router/>
        </generalSettingContext.Provider>
    )
}

export default App;