import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import {
  updateNudgeSettings,
} from "./services/general-settings.service";
import Settings from "./components/templates/settings/settings.component";
import SettingsPreview from "./components/templates/settings-preview/settings-preview.component";
import { IContext, generalSettingContext } from "src/contexts/context";
import CSS from "./general-settings.module.scss";
import { showDangerToast, showSuccessToast } from "src/services/toast.service";
import { TOAST_MESSAGES } from "src/constants/toastConstant";

const GeneralSettings: React.FunctionComponent = () => {
  const { application_id: applicationId } = useParams();
  const {
    generalSettings
  } = useContext(generalSettingContext) as IContext;

  const handleSave: Function = () => {
    if (generalSettings && applicationId) {
      (async () => {
        try {
            const response:any = await  updateNudgeSettings(applicationId, generalSettings);
            if(response.data.code === 200){
                showSuccessToast(TOAST_MESSAGES.nudgeUpdateSuccess); 
                return;
            }
            showDangerToast(TOAST_MESSAGES.nudgeUpdateError);
        } catch (error) {
          showDangerToast(TOAST_MESSAGES.nudgeUpdateError)
        }
      })();
    }
  };

  if (!generalSettings) return <></>;

  return (
    <div className={CSS["general-settings"]}>
      <div className={CSS["header"]}>
        <div className={CSS["title"]}>General Settings</div>
        <div className={CSS["description"]}>
          Set up positions, timing, devices, and themes for smart nudges
        </div>
      </div>
      <div className={CSS["settings"]}>
          <Settings handleSave={handleSave} />
          <SettingsPreview />
      </div>
    </div>
  );
};

export default GeneralSettings;
