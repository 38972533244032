import {  useEffect, useState } from "react"
import CSS from "./statistics.module.scss"
import StatisticsChip from "../../molecules/statistic-chip/statistic-chip.component";
import {  useParams } from "react-router-dom";
import { getAnalyticsData } from "../../../services/dashboard.service";

export interface INudgeStats{
    label:string,
    value:number,
    percentage_change:number,
    growth_type: "INCREASE" | "DECREASE" 
}

interface IStatisticsProps{
    timePeriod: number
}

const Statistics:React.FunctionComponent<IStatisticsProps> = ({timePeriod})=>{

    const [nudgeStats,setNudgeStats] = useState<Array<INudgeStats>>();
    const { application_id: applicationId } = useParams();

    // CALL API ACCORDING TO TYPE PASSED FROM PARENT;
    useEffect(()=>{
        if(applicationId){
        (async () => {
            const response = await getAnalyticsData(applicationId,timePeriod);
            if (response.data.results) {
                setNudgeStats(response.data.results as Array<INudgeStats>);
            }
            })();
        }
    },[applicationId,timePeriod])

    return <div className={CSS['container']}>
        {
            nudgeStats?.length && nudgeStats.map((item)=>{
                return <StatisticsChip data={item} timePeriod={timePeriod}/>
            })
        }
    </div>
}

export default Statistics