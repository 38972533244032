import React from "react";

import Positions from "./molecules/positions/positions.component";
import Timing from "./molecules/timing/timing.component";

import CSS from "./settings.module.scss";
import HorizontalDiv from "src/components/atoms/horizontal-div/horizontal-div.component";
import Devices from "./molecules/devices/devices.component";
import Themes from "./molecules/themes/themes.component";
import { Button } from "@gofynd/nitrozen-react";

const Settings: React.FunctionComponent<{ handleSave: Function }> = ({
  handleSave,
}) => {
  return (
    <div className={CSS["settings"]}>
      <div className={CSS["title"]}>Settings</div>
      <div className={CSS["settings-item"]}>
        <Positions />
        <HorizontalDiv />
        <Timing />
        <HorizontalDiv />
        <Devices />
        <HorizontalDiv />
        <Themes />
        <HorizontalDiv />
      </div>
      <Button
        size="medium"
        onClick={() => handleSave()}
        className={CSS["button"]}
      >
        Save
      </Button>
    </div>
  );
};

export default Settings;
