import dummyIcon from "../../../assets/icons/dummy_product_icon.svg";
import reviewStar from "../../../assets/icons/review_star.svg";
import { NUDGE_TYPE } from "../../../constants/globalConstants";


// Common Utility for text with highlight color
const getHighlightedWord = (word:string, color:string) => {
  return `<span style="color:${color};font-weight:700">${word}</span>`;
};

// Common Utility to get nudge icon
const getNudgeIcon = (data:any)=>{
    return data?.product_icon ?? dummyIcon;
}

// Common Utility to get stars UI
const getReviewStars = (numOfStars:number) =>{
    let stars = [];
    for(let i = 1 ; i <= numOfStars ; i++){
        const imgHtmlString = `<img src=${reviewStar} alt='review-star'></img>`;
        stars.push(imgHtmlString)
    }

    const htmlString = `<span>${stars.join('')}</span>`;

    return htmlString;
}

const getHighlightedProductName = (productName:string,productLink:string,highlightColor:string) => {
    const name = `<a style="text-decoration:none" href = "${
        productLink
    }" target="_blank"><u style="text-decoration-color:${highlightColor}">${getHighlightedWord(productName, highlightColor)}</u></a>`;
    return name;
}

const getProductNudgeContent = (type:string,data:any,highlightColor:string)=>{
    const { user_name= "John Smith" , user_location ="Mumbai,Maharashtra", product_name="Product Name" , product_link = "#" } = data; 

    const highlightedUserLocation = getHighlightedWord(user_location,highlightColor);
    const highlightedProductName = getHighlightedProductName(product_name,product_link,highlightColor);

    let content = "";

    switch(type){
        case NUDGE_TYPE.PRODUCT_SPECIFIC: 
        content = `${user_name} in ${highlightedUserLocation} is viewing ${highlightedProductName}`;
        break;
        case NUDGE_TYPE.ADD_TO_CART: 
        content = `${user_name} in ${highlightedUserLocation} added to cart ${highlightedProductName}`;  
        break;
        case NUDGE_TYPE.ORDER:
        content = `${user_name} in ${highlightedUserLocation} purchased ${highlightedProductName}`
    }

    return content;
}

const productReviewNudgeContent = (data:any,highlightColor:string) =>{
    const { user_name= "John Smith" , user_location ="Mumbai,Maharashtra", product_name="Product Name" ,product_link = "#" , num_of_stars = 5} = data;

    const highlightedUserLocation = getHighlightedWord(user_location,highlightColor);
    const highlightedProductName = getHighlightedProductName(product_name,product_link,highlightColor);
    const reviewHtmlString = getReviewStars(num_of_stars);

    const content = `${user_name} in ${highlightedUserLocation} reviewed ${highlightedProductName} with ${reviewHtmlString}`;

    return content;
}

const getNudgeContent = (type:string,data:any,color:string) =>{ // fallback dashboard
    let content = "";
    switch(type){
        case NUDGE_TYPE.PRODUCT_SPECIFIC: 
        content = getProductNudgeContent(type,data,color); 
        break;
        case NUDGE_TYPE.ADD_TO_CART: 
        content = getProductNudgeContent(type,data,color); 
        break;
        case NUDGE_TYPE.ORDER: 
        content = getProductNudgeContent(type,data,color); 
        break;
        case NUDGE_TYPE.PRODUCT_REVIEW:
        content = productReviewNudgeContent(data,color);
        break;
    }
    return content;
}

// Pass nudge data and highlight Color -> returns final object for nudge component according to Nudge type
export const createNudge = (nudge:any, highLightColor:string) => { // fallback for dashboard UI
    const type = nudge.type;
    const data = nudge.data;
    const color = highLightColor;
    const nudgeIcon = getNudgeIcon(data);
    const content = getNudgeContent(type,data,color);

    const finalObjForDashboard = {
        id: nudge.id,
        content: content,
        icon: nudgeIcon,
    };

    return finalObjForDashboard;
};