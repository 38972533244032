import axios, { AxiosHeaders } from "axios";
import { getCompanyId } from "./application.service";

const smartSalesPopsAxiosInstance = axios.create({
  headers: {
    "x-company-id": undefined,
  },
});

smartSalesPopsAxiosInstance.interceptors.request.use((config)=>{
    if(config && config.headers){
        (config.headers as AxiosHeaders).set("x-company-id",getCompanyId());
    }
    return config;
})

export default smartSalesPopsAxiosInstance;