import React, { useContext } from "react";

import CSS from "./positions-body.module.scss";
import { IContext, generalSettingContext } from "src/contexts/context";
import { Radio } from "@gofynd/nitrozen-react";

const PositionsBody: React.FunctionComponent = () => {
  const {
    generalSettings: {
      general_settings: { positions },
    },
    setGeneralSettings,
  } = useContext(generalSettingContext) as IContext;

  const handlePositionRadio: (type: string, value: string) => void = (
    type,
    value
  ) => {
    setGeneralSettings((prevState:any) =>
      prevState
        ? {
            ...prevState,
            general_settings: {
              ...prevState.general_settings,
              positions: {
                ...prevState.general_settings.positions,
                items: {
                  ...prevState.general_settings.positions.items,
                  [type]: {
                    ...prevState.general_settings.positions.items[type],
                    value: value,
                  },
                },
              },
            },
          }
        : undefined
    );
  };

  const { items: positionItems } = positions;

  return (
    <div className={CSS["position-body"]}>
      {Object.keys(positionItems).map((key) => (
        <div className={CSS["position"]} key={positionItems[key].type}>
          <div className={CSS["position-name"]}>{positionItems[key].name}</div>
          <div className={CSS["position-options"]}>
            {positionItems[key].items.map((option) => (
              <Radio
                id={option.type}
                labelText={option.name}
                name={key}
                onChange={() => handlePositionRadio(key, option.type)}
                radioValue={option.type}
                value={positionItems[key].value}
                key={option.type}
                className={CSS["position-option"]}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PositionsBody;
