import { NUDGE_TYPE } from "../constants/globalConstants";
import addToCartIcon from "../assets/icons/icon_add_to_cart.svg";
import productReviewIcon from "../assets/icons/icon_product_reviews.svg";
import orderIcon from "../assets/icons/icon_orders.svg";
import productSpecificIcon from "../assets/icons/icon_product_specific.svg";
import liveVisitorsIcon from "../assets/icons/icon_live_visitors.svg";
import recentVisitorsIcon from "../assets/icons/inon_recent_visitors.svg";
import cartSummaryIcon from "../assets/icons/icon_cart_summary.svg";
import orderSummaryIcon from "../assets/icons/icon_order_summary.svg";
import lowStockIcon from "../assets/icons/icon_low_stock.svg";

export const getIconForNudgeType = (nudgeType:string | number) =>{
    switch(nudgeType) {
        case NUDGE_TYPE.ADD_TO_CART: return addToCartIcon;
        case NUDGE_TYPE.PRODUCT_REVIEW: return productReviewIcon;
        case NUDGE_TYPE.ORDER: return orderIcon;
        case NUDGE_TYPE.PRODUCT_SPECIFIC: return productSpecificIcon;
        case NUDGE_TYPE.LIVE_VISITORS : return liveVisitorsIcon;
        case NUDGE_TYPE.RECENT_VISITORS: return recentVisitorsIcon;
        case NUDGE_TYPE.CART_SUMMARY: return cartSummaryIcon;
        case NUDGE_TYPE.ORDER_SUMMARY: return orderSummaryIcon;
        case NUDGE_TYPE.LOW_STOCK: return lowStockIcon;
    }
}

export const DEBOUNCE_DELAY = 500;

export const debounce = (func:Function, delay:number) => {
    let timerId:any;
    return function (e:any) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func(e);
      }, delay);
    };
};

export const formatDateTime = (timestamp:number)=>{
  let dateFormat = new Date(timestamp);
  const month = dateFormat.toLocaleString('default',{ month: 'short' });
  const date = dateFormat.getDate();
  const year = dateFormat.getFullYear();
  let hours:any = dateFormat.getHours();
  let min:any = dateFormat.getMinutes();
  
  if(hours < 10) hours = `0${hours}`;
  if(min < 10) min = `0${min}`;

  const time = `${hours}:${min}`;

  const formattedDate = `${month} ${date},${year} ${time}`;
  return formattedDate;
}

export const getNudgeTypeDisplayName = (type:string) =>{
  const words = type.split("_");
  for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1).toLowerCase();
  }    
  const displayName = words.join(" "); 
  return displayName;
}
  

export const isInputValueWithinRange  = (value:number,min:number,max:number) : boolean=>{
  if(value >= min && value<= max) return true;
  return false;
}