import axios from "../../../services/http.service";

export const getNudgeConfig = async (applicationId: string,nudgeType:string) => {
  return axios.get(`/platform/api/v1/nudge-config/${applicationId}/${nudgeType}`);
};

export const toggleNudgeActiveStatus = async (applicationId: string,nudgeType:string,isActive:boolean) => {
  return axios.put(`/platform/api/v1/nudge-config/toggle/${applicationId}/${nudgeType}?isActive=${isActive}`);
};

export const updateNudgeConfig = async (applicationId:string,nudgeType:string,config:Object) =>{
  return axios.put(`/platform/api/v1/nudge-config/${applicationId}/${nudgeType}`,{
    data:config
  });
}

interface QueryParams {
  search?: string;
  pageSize?: number;
  pageNumber?: number;
}

export const getProducts = async (applicationId:string,queryParams:QueryParams) =>{
  return axios.get(`/platform/api/v1/application/products/${applicationId}`,{
    params:queryParams
  })
}