import { Dropdown } from "@gofynd/nitrozen-react"
import MinInput from "../../atoms/min-input/min-input.component"
import HorizontalDiv from "../../../../../components/atoms/horizontal-div/horizontal-div.component";
import CSS from "./live-visitor-condition.module.scss"
import { useContext, useEffect, useState } from "react";
import { nudgeConfigContext } from "src/contexts/context";
import { ILiveVisitorCondition } from "@models/config-groups/live-visitor-condition-config";
import { isInputValueWithinRange } from "../../../../../utils/helpers";

interface ILiveVisitorConditionProps{
    config:ILiveVisitorCondition,
    isLast:boolean,
    setIsBtnDisabled:Function
}

const LiveVisitorCondition:React.FunctionComponent<ILiveVisitorConditionProps> = ({config,isLast,setIsBtnDisabled}) =>{
    const [liveVisitorCondition,setLiveVisitorCondition] = useState<ILiveVisitorCondition>(config);
    const {setNudgeConfig} = useContext(nudgeConfigContext);
    
    useEffect(()=>{
        if(isInputValueWithinRange(
            liveVisitorCondition.min_visitors.value,
            liveVisitorCondition.min_visitors.min,
            liveVisitorCondition.min_visitors.max) 
        ){
            setNudgeConfig((prevState:any) => 
                prevState ? {
                    ...prevState,
                    configs:{
                        ...prevState.configs,
                        live_visitor_condition:liveVisitorCondition
                    }
                }:undefined
            );
            setIsBtnDisabled(false);
        }  
    },[liveVisitorCondition,setNudgeConfig,setIsBtnDisabled]);

    const handleMinVisitorChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setLiveVisitorCondition((prevState:any)=> prevState ? {
            ...prevState,
            min_visitors:{
                ...prevState.min_visitors,
                value:Number(event?.target?.value)
            }
        }:undefined);
    }

    const handleTimePeriodChange = (value:string) => {
        setLiveVisitorCondition((prevState:any) => prevState ? {
            ...prevState,
            time_period:{
                ...prevState.time_period,
                value:Number(value)
            }
        }:undefined)
    }

    return (
        <>
            <div className={CSS['container']}>
                <div className={CSS["grid-of-two"]}>
                    <MinInput 
                        config={liveVisitorCondition.min_visitors} 
                        handleOnChange={handleMinVisitorChange}
                        setIsBtnDisabled={setIsBtnDisabled}
                    />
                    <div>
                        <Dropdown 
                            items={liveVisitorCondition.time_period.items}
                            label={liveVisitorCondition.time_period.title}
                            onChange={handleTimePeriodChange}
                            tooltip={liveVisitorCondition.time_period.tooltip}
                            tooltipPosition="top-start"
                            value={liveVisitorCondition.time_period.value}
                            className={CSS['dropdown-class']}
                        />
                    </div>
                </div>
            </div>
            {
                isLast ? <></> : <HorizontalDiv></HorizontalDiv>
            }
        </>
    )
}


export default LiveVisitorCondition