import ColorPalette from '../color-palette/color-palette.component';
import CSS from './highlight-color.module.scss'
import { IHighlightColor } from '@models/config-groups/highlight-color';
import { useContext, useEffect, useState } from 'react';
import { generalSettingContext, nudgeConfigContext } from '../../../../../contexts/context';
import HorizontalDiv from 'src/components/atoms/horizontal-div/horizontal-div.component';

interface IHighlightColorProps{
    config:IHighlightColor,
    isLast:boolean
}

const HighlightColor: React.FunctionComponent<IHighlightColorProps> = ({config,isLast}) =>{
    const { 
        generalSettings :{
            general_settings :{
                themes:{
                    value
                }
            }
        }
    
    } = useContext(generalSettingContext);
    const {setNudgeConfig} = useContext(nudgeConfigContext);
    const [highlightColor,setHighLightColor] = useState<IHighlightColor>(config);

    useEffect(()=>{
        setNudgeConfig((prevState:any) => 
            prevState ? {
                ...prevState,
                configs:{
                    ...prevState.configs,
                    highlight_color:highlightColor
                }
            }:undefined
        )    
    },[highlightColor,setNudgeConfig]);

    const handleColorChange = (color:string) =>{
        let key:string = "";
        if(value === "DARK") key = "dark_theme_palette";
        else key = "light_theme_palette";

        setHighLightColor((prevState:any) => prevState 
            ? {
                ...prevState,
                [key]:{
                    ...prevState[key],
                    value:color
                }
            } : undefined
        )
    }   
    
    return (
        <>
            <div className={CSS["container"]}>   
                <p className={CSS["title"]}>{highlightColor.title}</p>
                <p className={CSS['subheading']}>{highlightColor.description}</p>
                <ColorPalette 
                    colorPalette = {value === "DARK" ? highlightColor.dark_theme_palette : highlightColor.light_theme_palette}
                    selectedColorBorder={value === "DARK" ? "1px solid #2E31BE" : "1px solid #000"}
                    handleSelect={handleColorChange}
                />
            </div>
            {
                isLast ? <></>  : <HorizontalDiv></HorizontalDiv>
            }
        </>
    )
}

export default HighlightColor;