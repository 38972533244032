class MessageBase {
  auth: string;
  constructor(public action: string) {
    this.action = action;
    this.auth = 'cGxhdGZvcm1QcmV2aWV3';
  }
}

export class ConfigMessage extends MessageBase {

  constructor(public data: any) {
    super('configChanged');
  }
}

