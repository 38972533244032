import { useEffect, useState } from "react";
import { useSearchParams ,useParams} from "react-router-dom";
import { INudgeConfig } from "./nudge-setting.types";
import { nudgeConfigContext } from "../../contexts/context";
import ConfigPanel from "./components/layouts/config-panel/config-panel.component";
import ConfigPageHeader from "./components/molecules/config-page-header/config-page-header.component";
import CSS from "./nudge-setting.module.scss";
import NudgeConfigRightPanel from "./components/layouts/nudge-config-right-panel/nudge-config-right-panel.component";
import { getNudgeConfig, updateNudgeConfig } from "./services/nudge-setting.service";
import { showDangerToast, showSuccessToast } from "src/services/toast.service";
import { TOAST_MESSAGES } from "../../constants/toastConstant";

const NudgeConfigPage: React.FunctionComponent = () =>{

    const [nudgeConfig, setNudgeConfig] = useState<INudgeConfig>({} as INudgeConfig);
    const { application_id: applicationId } = useParams();
    const [searchParams] = useSearchParams();

    useEffect(()=>{
        const nudgeType :string | null= searchParams.get("nudgeType");
        // Get Nudge Config for NudgeType
        if (applicationId && nudgeType) {
            (async () => {
              let response = await getNudgeConfig(applicationId,nudgeType);   
              setNudgeConfig(response.data.results);
            })();
        }
    },[searchParams,applicationId]);

    const handleSave = () =>{
        const nudgeType :string | null= searchParams.get("nudgeType");
        if(nudgeConfig && applicationId && nudgeType){
            (async () => {
                try {
                    const response:any = await updateNudgeConfig(applicationId,nudgeType,nudgeConfig);
                    if(response.data.code === 200){
                        showSuccessToast(TOAST_MESSAGES.nudgeUpdateSuccess); 
                        return;
                    }
                    showDangerToast(TOAST_MESSAGES.nudgeUpdateError);
                } catch (error) {
                  showDangerToast(TOAST_MESSAGES.nudgeUpdateError)
                }
            })();
        }
    }

    return (
        <nudgeConfigContext.Provider value={{
            nudgeConfig:nudgeConfig,
            setNudgeConfig:setNudgeConfig
        }}>
            {Object.keys(nudgeConfig).length > 0 && (
                <div className={CSS["config-page-container"]}>
                    <ConfigPageHeader /> 
                    <div className={CSS["config-page-body"]}>
                        <ConfigPanel handleSave={handleSave}/>
                        <NudgeConfigRightPanel />    
                    </div>
                </div>
            )}
            
        </nudgeConfigContext.Provider>
    )
}


export default NudgeConfigPage;