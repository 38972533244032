import { useContext, useState } from "react";
import CSS from "./config-panel.module.scss"
import { Button } from "@gofynd/nitrozen-react";
import { nudgeConfigContext } from "../../../../../contexts/context";
import useConfigComponents from "../../../hooks/use-config-component";

interface IConfigPanel {
    handleSave:Function
}

const ConfigPanel = ({handleSave}:IConfigPanel) =>{
    
    const { nudgeConfig } = useContext(nudgeConfigContext);
    const [isBtnDisabled,setIsBtnDisabled] = useState<boolean>(false);

    const configPanelComponents = useConfigComponents({
        nudgeConfig,
        setIsBtnDisabled
    });

    return (
        <div className={CSS["container"]}>
            <div>
                <p className={CSS["title"]}>Settings</p>
                <div>
                    {configPanelComponents}
                </div>
                <div>
                    <Button
                        size="medium"
                        onClick={() => handleSave()}
                        disabled={isBtnDisabled}
                        className={CSS["button"]}
                    >
                        Save
                    </Button>
                </div>
            </div>   
        </div>
    )
}

export default ConfigPanel;