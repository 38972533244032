import React, { MouseEventHandler, useContext } from "react";

import CSS from "./header.module.scss";
import { IContext, generalSettingContext } from "src/contexts/context";
import iconCollapse from "../../../../../assets/icons/icon_accordian_collapse.svg";
import iconExpand from "../../../../../assets/icons/icon_accordian_expand.svg";

interface IHeader {
  title: string;
  description: string;
  collapse: Boolean;
  handleClick: MouseEventHandler<HTMLElement>;
}

const Header: React.FunctionComponent<IHeader> = ({
  title,
  description,
  collapse,
  handleClick,
}) => {
  const {
    generalSettings: {
      general_settings: { positions },
    },
    setGeneralSettings,
  } = useContext(generalSettingContext) as IContext;

  return (
    <div className={CSS["header"]} onClick={handleClick}>
      <div>
        <div className={CSS["title"]}>{title}</div>
        <div className={CSS["description"]}>{description}</div>
      </div>
      <div>
        {collapse ? <img src={iconExpand} /> : <img src={iconCollapse} />}
      </div>
    </div>
  );
};

export default Header;
