import React from "react";
import { SvgIcArrowNext } from "@gofynd/nitrozen-react";
import CSS from "./card.module.scss";

export interface IFeatureType {
  id:  string;
  iconSrc?: string;
  title: string;
  description?: string;
  showActive?: boolean;
  active?: boolean;
  comingSoon?:boolean;
  handleClick?: (id:string) => void;
}

const Card: React.FunctionComponent<IFeatureType> = ({
  handleClick = (id) => {
    return id;
  },
  title = "",
  description = "",
  iconSrc = "",
  comingSoon = false,
  showActive = false,
  active = "",
  id = ""
}) => {
  return (
    <div className={CSS["card"]}>
      {iconSrc.length > 0 && (
        <div className={CSS["card-logo-style"]}>
          <img src={iconSrc} data-testid="card_logo" alt=""/>
        </div>
      )}
      <div className={CSS["card-content"]}>
        <h4 data-testid="card_title">{title}</h4>
        <p data-testid="card_description">{description}</p>
      </div>
      <div className={CSS["card-footer"]}>
        {
          !comingSoon && (
            <div
              className={CSS["arrow"]}
              onClick={()=>handleClick(id)}
              data-testid="card_handle_click"
            >
              <SvgIcArrowNext />
            </div>
          )
        }
        {
          comingSoon && (
            <div className={CSS["inactive"]}>
              COMING SOON
            </div>
          )
        }
        {showActive && !comingSoon && (
          <div className={active ? CSS["active"] : CSS["inactive"]}>
            {active ? "ACTIVE" : "INACTIVE"}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
