import axios from "../../../services/http.service";

interface QueryParams {
  search?: string;
  limit?: number;
  page?: number;
}

const getApplications = async (queryParams: QueryParams) => {
  return axios.get("/platform/api/v1/application", { params: queryParams });
};

export default getApplications;
