import axios from "../../../services/http.service";
import qs from "qs";

interface IQueryParamGetAllNudges{
  page: number,
  limit:number,
  nudgeType:Array<string>
}

export const getAnalyticsData = async (applicationId: string,timePeriod:number) => {
  return axios.get(`/platform/api/v1/analytics/${applicationId}`,{
    params:{
      timePeriod:timePeriod
    }
  });
};

export const getAllNudgesData = async (applicationId: string,queryParams:IQueryParamGetAllNudges) => {
  return axios.get(`/platform/api/v1/nudges/${applicationId}`,{ 
    params: queryParams,
    paramsSerializer: params => {
      return qs.stringify(params)
    }
  });
};

export const deactivateNudges = async (nudgeIds:Array<string>) =>{
  return axios.put('/platform/api/v1/nudges/deactivate-nudges',{
    data:nudgeIds
  })
}

// export const deactivateAllNudges = async (applicationId: string,nudgeType:Array<string>) =>{
//   return axios.put(`/platform/api/v1/nudges/${applicationId}`,{
//     nudgeType:nudgeType
//   });
// }


