import { Route, Routes } from "react-router-dom";
import SalesChannelPage from "../pages/sales-channel/sales-channel.component";
import FeaturesPage from "../pages/features/features.component";
import PageWrapper from "../components/molecules/page-wrapper/page-wrapper.component";
import NudgeListPage from "../pages/nudge-listing/nudge-listing.component";
import GeneralSettings from "../pages/general-settings/general-settings.component";
import NudgeConfigPage from "src/pages/nudge-settings/nudge-setting.component";
import Dashboard from "src/pages/dashboard/dashboard.component";
import { RouteWrapper } from "src/components/molecules/route-wrapper/route-wrapper.component";
import PaymentPricingPage from "src/pages/subscription/pricing-plan.component";
import { SubscriptionStatus } from "src/components/molecules/subscription-status/subscription-status.component";

const BASE_PATH = "/company/:company_id";
const APPLICATION_PATH = "/application/:application_id";

const ROUTE_PATH = {
  HOME_PAGE: BASE_PATH,
  FEATURES_PAGE: `${BASE_PATH}${APPLICATION_PATH}/features-page`,
  CUSTOMIZATION_PAGE: `${BASE_PATH}${APPLICATION_PATH}/customize-pops`,
  GENERAL_SETTINGS_PAGE: `${BASE_PATH}${APPLICATION_PATH}/general-settings`,
  NUDGE_CONFIG_PAGE:`${BASE_PATH}${APPLICATION_PATH}/nudge-config`,
  DASHBOARD_PAGE:`${BASE_PATH}${APPLICATION_PATH}/dashboard`,
  PRICING_PLAN_PAGE:`${BASE_PATH}/payment`,
  SUBSCRIPTION_STATUS_PAGE:`${BASE_PATH}/subscription-status`
};

const Router = () => {
  return (
    <Routes>
      <Route
        path={ROUTE_PATH.HOME_PAGE}
        element={
          <RouteWrapper>
            <PageWrapper breadCrumb={{ showBreadCrumb: false, label: "Home" }}>
              <SalesChannelPage />
            </PageWrapper>
          </RouteWrapper>
        }
      ></Route>
      <Route
        path={ROUTE_PATH.DASHBOARD_PAGE}
        element={
          <RouteWrapper>
              <PageWrapper
                breadCrumb={{ showBreadCrumb: true, label: "Dashboard" }}
              >
                <Dashboard/>
              </PageWrapper>
          </RouteWrapper>
        }
      ></Route>
      <Route
        path={ROUTE_PATH.FEATURES_PAGE}
        element={
          <RouteWrapper>
              <PageWrapper breadCrumb={{ showBreadCrumb: true, label: "Features" }}>
                <FeaturesPage />
              </PageWrapper>
          </RouteWrapper>
        }
      ></Route>
      <Route
        path={ROUTE_PATH.CUSTOMIZATION_PAGE}
        element={
          <RouteWrapper>
            <PageWrapper
              breadCrumb={{ showBreadCrumb: true, label: "Customize Pops" }}
            >
              <NudgeListPage />
            </PageWrapper>
          </RouteWrapper>
        }
      ></Route>
      <Route
        path={ROUTE_PATH.GENERAL_SETTINGS_PAGE}
        element={
          <RouteWrapper>
            <PageWrapper
              breadCrumb={{ showBreadCrumb: true, label: "General Settings" }}
            >
              <GeneralSettings />
            </PageWrapper>
          </RouteWrapper>
        }
      ></Route>
      <Route
        path={ROUTE_PATH.NUDGE_CONFIG_PAGE}
        element={
          <RouteWrapper>
            <PageWrapper
              breadCrumb={{ showBreadCrumb: true, label: "Nudge Settings" }}
            >
              <NudgeConfigPage />
            </PageWrapper>
          </RouteWrapper>
        }
      ></Route>
      <Route
        path={ROUTE_PATH.PRICING_PLAN_PAGE}
        element={
            <PaymentPricingPage />
        }
      ></Route>
      <Route
        path={ROUTE_PATH.SUBSCRIPTION_STATUS_PAGE}
        element={
          <SubscriptionStatus />
        }
      ></Route>
    </Routes>
  );
};

export default Router;
