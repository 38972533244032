import { ApplicationPage } from "@models/application-page";

class SalesChannelPagesService {
  setPages(pages: ApplicationPage[]): void {
    sessionStorage.setItem("salesChannelPages", JSON.stringify(pages));
  }

  getPages(): ApplicationPage[] {
    try {
      return JSON.parse(sessionStorage.getItem("salesChannelPages") || "[]")
    } catch (err) {
      console.error(err);
      return []
    }
  }
}

const salesChannelPagesService = new SalesChannelPagesService();

export default salesChannelPagesService;
