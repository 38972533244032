import React, { useContext, useState } from "react";

import Header from "src/pages/general-settings/components/molecules/header/header.component";
import { IContext, generalSettingContext } from "src/contexts/context";
import ThemesBody from "./components/molecules/themes-body/themes-body.component";

const Themes: React.FunctionComponent = () => {
  const {
    generalSettings: {
      general_settings: { themes },
    },
    setGeneralSettings,
  } = useContext(generalSettingContext) as IContext;

  const [collapse, setCollapse] = useState(true);
  const handleCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <div className="themes">
      <Header
        title={themes.name}
        description={themes.description}
        collapse={collapse}
        handleClick={handleCollapse}
      />
      {!collapse && <ThemesBody />}
    </div>
  );
};

export default Themes;
