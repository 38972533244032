import { useCallback, useEffect, useState } from "react";
import PricingCard from "./molecules/pricing-card.component";
import { getAllPlans } from "../../services/subscription.service";
import { useParams } from "react-router-dom";
import CSS from "./pricing-plan.module.scss";

const PaymentPricingPage = () => {
  const [planList, setPlanList] = useState([]);
  const { company_id } = useParams();

  const getPlanData = useCallback(async () => {
    try {
      const res = await getAllPlans({ company_id });

      if (res?.data?.results) {
        setPlanList(res?.data?.results);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getPlanData();
  }, [getPlanData]);

  return (
    <div className={CSS["container"]}>
      <div className={CSS["heading"]}>Pricing</div>
      <div className={CSS["description"]}>
        Select plan suitable for you and your business need.
      </div>
      <div className={CSS["card_list"]}>
        {planList?.map((plan) => {
          return <PricingCard plan={plan} />;
        })}
      </div>
    </div>
  );
};

export default PaymentPricingPage;
