import React, { useContext } from "react";
import TimingBodyItem from "../timing-body-item/timing-body-item.component";
import { IContext, generalSettingContext } from "src/contexts/context";
import CSS from "./timing-body.module.scss";

const TimingBody: React.FunctionComponent = () => {
  const {
    generalSettings: {
      general_settings: {
        timing: { items: timingItems },
      },
    },
    setGeneralSettings,
  } = useContext(generalSettingContext) as IContext;

  const handleTimingSlider = (type: string, value: number) => {
    setGeneralSettings((prevState:any) =>
      prevState
        ? {
            ...prevState,
            general_settings: {
              ...prevState.general_settings,
              timing: {
                ...prevState.general_settings.timing,
                items: {
                  ...prevState.general_settings.timing.items,
                  [type]: {
                    ...prevState.general_settings.timing.items[type],
                    value: value,
                  },
                },
              },
            },
          }
        : undefined
    );
  };

  return (
    <div className={CSS["timing-body"]}>
      {Object.keys(timingItems).map((key) => (
        <TimingBodyItem
          {...timingItems[key]}
          handleTimingSlider={handleTimingSlider}
          key={timingItems[key].type}
        />
      ))}
    </div>
  );
};

export default TimingBody;
