import CSS from "./empty-dashboard.module.scss";
const emptyData =  require("../../../../../assets/images/empty-state.png");

const EmptyDashboard: React.FunctionComponent = () => {

  return (
    <div className={CSS.empty_content}>
        <img
            src={emptyData}
            data-testid="EMPTY_BAR_IMAGE"
            className={CSS.image}
            alt="empty_state_image"
        />
        <h2 className={CSS.heading} >
            No Data Available
        </h2>
    </div>
  );
};

export default EmptyDashboard;
