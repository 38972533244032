import React, { ChangeEventHandler, useEffect, useRef } from "react";

import CSS from "./slider.module.scss";

export interface ISlider {
  min: number;
  max: number;
  step: number;
  defaultValue?: number;
  labels?: Array<string>;
  onChange: ChangeEventHandler;
  optionSuffix: string;
  style?: {
    scrollColor?: string;
    width?: string;
  };
}

const Slider: React.FunctionComponent<ISlider> = (props) => {
  const { min, max, step, defaultValue, onChange, style, optionSuffix } = props;
  const sliderRef = useRef<HTMLInputElement | null>(null);

  const calculateBackground = (sliderValue:any)=>{
    const progress = ((Number(sliderValue) - min) / (max - min)) * 100;
    if (sliderRef?.current)
      sliderRef.current.style.background = `linear-gradient(90deg, #2e31be ${progress}%, #e7eeff ${progress}%)`;
  }

  useEffect(()=>{
    calculateBackground(defaultValue);
  },[]);
  
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sliderValue = sliderRef?.current?.value;
    calculateBackground(sliderValue);
    onChange(event);
  };

  
  return (
    <div className={CSS["slider"]}>
      <div
        className={CSS["slide-range"]}
        style={{
          width: `${style?.width}`,
        }}
      >
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          defaultValue={defaultValue}
          onChange={handleSliderChange}
          ref={sliderRef}
          data-testid="slide-input"
        />
      </div>

      <datalist style={{ width: `${style?.width}` }}>
        {Array(Math.floor((max - min + step) / step))
          .fill(0)
          .map((num, idx) => (
            <option
              value={min + idx * step}
              label={`${(min + idx * step).toString()}${
                optionSuffix ? optionSuffix : ""
              }`}
              key={idx}
            ></option>
          ))}
      </datalist>
    </div>
  );
};

Slider.defaultProps = {
  step: 0,
};

export default Slider;
