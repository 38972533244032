import { Button, Dropdown } from "@gofynd/nitrozen-react"
import CSS from "./nudge-event-library.module.scss"
import NudgeTable from "../nudge-table/nudge-table.component";
import { useCallback, useEffect, useState } from "react"
import Pagination from "../../molecules/pagination/pagination";
import {  deactivateNudges, getAllNudgesData } from "src/pages/dashboard/services/dashboard.service";
import {  useParams } from "react-router-dom";
import AlertDialog from "../../molecules/alert-dialog/alert-dialog.component";
import { showDangerToast, showSuccessToast } from "../../../../../services/toast.service";
import { TOAST_MESSAGES } from "../../../../../constants/toastConstant";
import { NUDGE_TYPE } from "../../../../../constants/globalConstants";
import { getNudgeTypeDisplayName } from "src/utils/helpers";
import EmptyDashboard from "../../molecules/empty-dashboard/empty-dashboard.component";

const pageSizeOptions:Array<number> = [10, 20, 50];
const nudgeTypesForDropdown = [NUDGE_TYPE.ORDER,NUDGE_TYPE.ADD_TO_CART,NUDGE_TYPE.PRODUCT_REVIEW];

const getDropDownValues =()=>{
    let nudgeTypes = [{ text:"All", value:"ALL"}]
    
    const types = nudgeTypesForDropdown.map((item:any)=>{
        return {
            text:getNudgeTypeDisplayName(item),
            value:item
        }   
    });

    nudgeTypes = [...nudgeTypes,...types];
    
    return nudgeTypes;
}

const NudgeEventLibrary:React.FunctionComponent = ()=>{

    // NUDGE DATA STATES
    const [nudgeData,setNudgeData] = useState<any>(null); // This State contains API DATA for CURRENT PAGE ONLY
    const [selectedNudgeId,setSelectedNudgeId] = useState<Array<string>>([]);
    const [shouldDeleteAll,setShouldDeleteAll] = useState<boolean>(false);
    const [deleteOneId,setDeleteOneId] = useState<string>("");

    // DROPDOWN STATES
    const [selectedNudgeType,setSelectedNudgeType] = useState<string>("ALL");

    // PAGINATION STATES
    const [limit,setLimit] = useState<number>(10);
    const [current,setCurrent] = useState<number>(1);

    // MODAL STATES
    const [modalState,setModalState] = useState<any>({
        isOpen:false,
        message:"",
        type:null
    });

    const { application_id: applicationId } = useParams();

    const getAllNudges = async (currentPage:number,pageSize:number,selectedNudgeType:string)=>{
        let nudgeTypesToFetch = [];

        // SEGREGATING ALL FILTER IN FRONTEND 
        if(selectedNudgeType === "ALL"){
            nudgeTypesToFetch = [...nudgeTypesForDropdown]
        }else{
            nudgeTypesToFetch = [selectedNudgeType]
        }

        if(applicationId){
            const response = await getAllNudgesData(applicationId,{
                page: currentPage,
                limit:pageSize,
                nudgeType:nudgeTypesToFetch
            });
            if(response.data.results.items.length === 0 && current > 1){
                setCurrent((prev) => prev - 1);
                return;
            }
            setNudgeData(response.data.results as any);
        } 
    }

    useEffect(()=>{
        if(applicationId){
            (async () => {
                await getAllNudges(current,limit,selectedNudgeType); 
                setShouldDeleteAll(false);
                setDeleteOneId("");
                setSelectedNudgeId([]);
            })();
        }
    },[applicationId,limit, current,selectedNudgeType]);

    const handleDropdownChange = (value:string)=>{
        setSelectedNudgeType(value);
        setCurrent(1);
    }

    const handleOnAllSelect = (value:boolean)=>{
        // Checkbox is checked
        if(value){
            setShouldDeleteAll(true);
            const allNudgeIdForThisPage = nudgeData.items.map((item:any)=>{
                return item._id
            }) ;
            setSelectedNudgeId(allNudgeIdForThisPage);
            return;
        }

        // if checkbox is unchecked
        setShouldDeleteAll(false);
        setSelectedNudgeId([]);
    };

    const handleOnSelect= (value:boolean,id:string)=>{
        // if checkbox is checked
        if(value)
        {
            setSelectedNudgeId((prev)=>[...prev,id]);
            return;
        }
        // if checkbox is unchecked
        const filteredArray = selectedNudgeId.filter((item)=>{
            return item !== id 
        });
        setSelectedNudgeId(filteredArray);
        setShouldDeleteAll(false) 
    }   

    const handleDelete = async (id:string)=>{
        setDeleteOneId(id);
        setModalState({
            isOpen:true,
            message:"This will delete the smart pop from the list permanently",
            type:"SINGLE_ROW"
        });
    }

    const handleDeleteAll = async ()=>{
        console.log("selectedNudgeId",selectedNudgeId);
        const totalItemsToBeDeleted = shouldDeleteAll ? nudgeData.items.length : selectedNudgeId.length;
        setModalState({
            isOpen:true,
            message:`This will delete ${totalItemsToBeDeleted} smart pops from the list permanently`,
            type:"MULTIPLE_ROW"
        })
    }
    
    const deleteOne = async (id:string)=>{
        try{
            const { data } = await deactivateNudges([id]);
            if(data?.code === 200){
                showSuccessToast(TOAST_MESSAGES.nudgeDeactivated);
                getAllNudges(current,limit,selectedNudgeType);
                setModalState({
                    isOpen:false,
                    message:"",
                    type:null
                });
            }
        }catch(err){
            showDangerToast(TOAST_MESSAGES.nudgeDeactivatedError);
            setModalState({
                isOpen:false,
                message:"",
                type:null
            })
        }
    }

    const deleteMany = async ()=>{
        if(applicationId){
            try{
                const { data } = await deactivateNudges(selectedNudgeId);
                if(data?.code === 200){
                    showSuccessToast(TOAST_MESSAGES.nudgeDeactivated);
                    getAllNudges(current,limit,selectedNudgeType);
                    setModalState({
                        isOpen:false,
                        message:"",
                        type:null
                    });
                }
            }catch(err){
                showDangerToast(TOAST_MESSAGES.nudgeDeactivatedError);
                setModalState({
                    isOpen:false,
                    message:"",
                    type:null
                })
            }
        }
    }

    const handleConfirmClick = async (type:string)=>{
        switch(type){
            case "SINGLE_ROW":  await deleteOne(deleteOneId);
                                break;
            case "MULTIPLE_ROW": await deleteMany();
        }    
    }

    const handleCancelClick = ()=>{
            setModalState({
                isOpen:false,
                message:"",
                type:null
            })
    }
    
    const handlePaginationChange = useCallback(
        async (paginationData: any) => {
            setLimit(paginationData.limit);
            setCurrent(paginationData.current);
    },[]);

    return <div className={CSS['container']}>
        <div className={CSS['header']}>
            <div className={CSS['title']}>
                <span>Pop Event Library</span>
            </div>
            <div className={CSS['btn-container-dropdown']}>
                <div className={CSS['dropdown-container']}>
                    <Dropdown
                        items={getDropDownValues()} 
                        placeholder="Select Type of Nudge"
                        value={selectedNudgeType} 
                        onChange={handleDropdownChange}
                        className={CSS['dropdownClass']}
                    />
                </div>
                <div className={CSS['btn-container']}>
                    <Button 
                    size="medium"
                    onClick={handleDeleteAll}
                    className={CSS["button"]}
                    disabled={selectedNudgeId.length === 0 && !shouldDeleteAll}
                    >
                        Delete All
                    </Button>
                </div>
            </div>
        </div>
        {
            nudgeData && nudgeData.items.length > 0 ? (
                <>
                    <NudgeTable 
                        tableData={nudgeData.items} 
                        handleOnAllSelect={handleOnAllSelect} 
                        selectedNudgeId={selectedNudgeId}
                        handleOnSelect={handleOnSelect}
                        handleDelete={handleDelete}
                        shouldDeleteAll={shouldDeleteAll}
                    />
                    <Pagination 
                        id="nudge-library-pagination"
                        data-testid="pagination"
                        mode={undefined}
                        name="Rows"
                        handlePaginationChange={handlePaginationChange}
                        value={{
                            limit,
                            current,
                            total: nudgeData.page.item_total ?? 0,
                        }}
                        pageSizeOptions={pageSizeOptions}
                        setCurrentPage={setCurrent}
                    />
                    {
                        modalState.isOpen && <AlertDialog
                            handleCancelClick={handleCancelClick}
                            handleConfirmClick={handleConfirmClick}
                            modalState={modalState}
                        />
                    }
                </> 
            )
            : (
                <EmptyDashboard />
            )
        }
    </div>
}

export default NudgeEventLibrary