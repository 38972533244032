import React, { useEffect, useRef, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { ToggleButton } from "@gofynd/nitrozen-react";
import { toggleExtensionActive } from "../../services/features.service";
import CSS from "./page-title.module.scss";
import { showDangerToast, showSuccessToast } from "src/services/toast.service";
import { TOAST_MESSAGES } from "src/constants/toastConstant";

const PageTitle: React.FunctionComponent = () => {
  const [isChannelActive, setIsChannelActive] = useState<boolean | undefined>(
    undefined
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const isFirstRender = useRef(false);
  const { company_id: companyId, application_id: applicationId } = useParams();

  useEffect(() => {
    const isActive = searchParams.get("is_active") || undefined;
    if (isActive !== undefined) {
      setIsChannelActive(() => (isActive === "true" ? true : false));
    }
  }, []);

  useEffect(() => {
    (async () => {
      let successMsg = isChannelActive ? TOAST_MESSAGES.extensionEnabled : TOAST_MESSAGES.extensionDisabled;
      let errorMsg = isChannelActive ? TOAST_MESSAGES.extensionEnabledError : TOAST_MESSAGES.extensionDisabledError;
      try{
        if (!applicationId || !companyId || isChannelActive === undefined) {
          return;
        }
        if (!isFirstRender.current) {
          isFirstRender.current = true;
          return;
        }
        const response = await toggleExtensionActive(companyId, applicationId, isChannelActive);
        if(response.status === 200){
          showSuccessToast(successMsg);
        }else{
          showDangerToast(errorMsg);
        }
        setSearchParams({ is_active: isChannelActive.toString() });
      }catch{
        showDangerToast(errorMsg);
      }
     
    })();
  }, [isChannelActive]);

  const handleToggleChannel = () => {
    setIsChannelActive(!isChannelActive);
  };

  return (
    <div className={CSS["home-page-title"]}>
      <span>Features</span>
      <div className={CSS["toggle-button-container"]}>
        <span className={CSS['toggle-text']}>{ isChannelActive ? "Enabled" : "Disabled" }</span>
        <ToggleButton
          id="smart-sales-toggle"
          value={isChannelActive}
          onToggle={handleToggleChannel}
          size="medium"
          style={{ paddingRight: 0 }}
        />
      </div>
    </div>
  );
};

export default PageTitle;
