import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CSS from "./features.module.scss";
import customizePopsIcons from "../../assets/icons/icon_customize_pops.svg";
import settingsIcon from "../../assets/icons/icon_settings.svg";
import dashboardIcon from "../../assets/icons/icon_dashboard.svg";
import PageTitle from "./components/molecules/page-title.component";
import Card from "./components/atoms/card.component";
import { LearningLinks } from "nitrozen-react-extension";
import { IFeatureType } from "./components/atoms/card.component";
import { createDefaultSettings, getGeneralSettings } from "../general-settings/services/general-settings.service";
import { IContext, generalSettingContext } from "../../contexts/context";
import { INudgeSettings } from "../general-settings/general-settings.types";
import salesChannelPagesService from "../../services/saleschannel-pages.service";
import { getAllPagesForSalesChannel } from "./services/features.service";

interface ILearningLink {
  type: "ARTICLE" | "VIDEO";
  imageSrc: string;
  title: string;
  duration: string;
  link: string;
}

const FeaturesPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { company_id: companyId, application_id: applicationId } = useParams();
  const {
    setGeneralSettings
  } = useContext(generalSettingContext) as IContext;

  // Storing All pages list of particular sales channel to session storage
  useEffect(() => {
    if (applicationId) {
      (async () => {
        const res = await getAllPagesForSalesChannel(applicationId);
        if (res?.data) {
          salesChannelPagesService.setPages(res?.data);
        }
      })();
    }
  }, [applicationId]);
  
  useEffect(() => {
    if (applicationId) {
      (async () => {
        let response = await getGeneralSettings(applicationId);

        if (!response.data.results) {
          response = await createDefaultSettings(applicationId);
          console.log("get Response", response.data);
        }

        setGeneralSettings(response.data.results as INudgeSettings);
      })();
    }
  }, [applicationId,setGeneralSettings]);

  const featuresList: Array<IFeatureType> = [
    {
      id: 'DASHBOARD',
      iconSrc: dashboardIcon,
      title: "Dashboard",
      description:
        "Access comprehensive analysis of different activities related to smart nudges",
      handleClick: (id:string) => {
        navigate(
          `/company/${companyId}/application/${applicationId}/dashboard`
        );
      },
    },
    {
      id: 'CUSTOMIZE_NUDGE',
      iconSrc: customizePopsIcons,
      title: "Customize Nudges",
      description: "Customize different nudges from a list of available options",
      handleClick: (id:string) => {
        navigate(
          `/company/${companyId}/application/${applicationId}/customize-pops`
        );
      },
    },
    {
      id: 'SETTINGS',
      iconSrc: settingsIcon,
      title: "Settings",
      description: "Customize general settings, timings and the appearance of nudges",
      handleClick: (id:string) => {
        navigate(
          `/company/${companyId}/application/${applicationId}/general-settings`
        );
      },
    },
  ];

  const LearningLinksData: Array<ILearningLink> = [
    {
      type: "ARTICLE",
      imageSrc:
        "https://hdn-1.addsale.com/x0/platform/extensions/android_app_builder/free/original/fYUhuVha0-Screenshot-2021-08-19-at-12.11-1.png",
      title: "How to setup and use this extension?",
      duration: "2min 30sec",
      link: "https://platform.fynd.com/help/docs/extensions/available-extensions/smart-nudges/",
    },
  ];

  return (
    <div className={CSS["features-page"]}>
      <div className={CSS["features-page-left"]}>
        <PageTitle />
        <div className={CSS["features-page-cards"]}>
          {featuresList.map((feature, idx) => (
            <Card {...feature} />
          ))}
        </div>
      </div>
      <LearningLinks
        heading={"Learning Links"}
        items={LearningLinksData}
        link={
          "https://platform.fynd.com/help/docs/manage-website/create-website/"
        }
      />
    </div>
  );
};

export default FeaturesPage;
