import axios from "./http.service";

export const getAllPlans = async (queryParams) => {
  return axios.get(`/platform/api/v1/subscription/plans`, {
    params: queryParams,
  });
};

export const getSubscriptionStatus = async (queryParams) => {
  return axios.get(`/platform/api/v1/subscription/`, {
    params: queryParams,
  });
};

export const subscribeToPlan = async (planId, queryParams) => {
  return axios.post(`/platform/api/v1/subscription/${planId}`, null, {
    params: queryParams,
  });
};

export const updateSubscriptionStatus = async (subscriptionId, queryParams) => {
  return axios.post(`/platform/api/v1/subscription/${subscriptionId}/status`, null, {
    params: queryParams,
  });
};
