import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PopCategories from "./components/molecules/pop-categories.component";
import CSS from "./nudge-listing.module.scss";
import { ICustomizePop } from "./nudge-listing.types";
import getNudgeListingPageData from "./services/nudge-listing.service";

const NudgeListPage: React.FunctionComponent = () => {
  const {application_id: applicationId } = useParams();
  const [showCategory, setShowCategory] = useState("RECENT_PURCHASE");
  const [nudgeCategories,setNudgeCategories] = useState<ICustomizePop>();

  useEffect(()=>{
    if(applicationId){
      (async () => {
        try {
          let response = await getNudgeListingPageData(applicationId);
          if(response.data.results){
            setNudgeCategories(response.data.results);
          }
        } catch (error) {
          /**
           * @todo handle error
           */
        }
      })();
    }
  },[applicationId]);

  const handleShow = (type: string) => {
    if (showCategory === type) {
      setShowCategory("CLOSE");
      return;
    }
    setShowCategory(type);
  };
  
  return (
    <div className={CSS["customize-pops"]}>
      <div className={CSS["header"]}>Customize Nudges</div>
      <div className={CSS["categories"]}>
        {nudgeCategories && nudgeCategories.categories.map((category, idx) => (
          <div>
            <PopCategories
              popCategory={category}
              show={showCategory === category.type}
              handleShow={handleShow}
              nudgeActiveStatus={nudgeCategories.nudge_active_status}
            />
            {idx !== nudgeCategories.categories.length - 1 && (
              <div className={CSS["horizontal-line"]}></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NudgeListPage;
