import React, { useMemo } from "react";
import configComponentFactory from "../factories/config-component-factory";
import { INudgeConfig } from "../nudge-setting.types";
import { INudgeCategory } from "@models/nudge-category";

export type useConfigComponentsType = {
   nudgeConfig:INudgeConfig,
   setIsBtnDisabled:Function
};


const useConfigComponents = ({
    nudgeConfig,
    setIsBtnDisabled
}: useConfigComponentsType) => {

    const components = useMemo(() => {
        if (nudgeConfig && nudgeConfig.configs) {
            return Object.keys(nudgeConfig.configs).map((key, index) => {
                const configKey = key as keyof INudgeCategory["configs"];
                if (nudgeConfig.configs[configKey]) {
                    const config = nudgeConfig.configs[configKey];
                    const component =
                        configComponentFactory.getComponent(configKey);
                    const isLast = (index === Object.keys(nudgeConfig.configs).length - 1)
                    const type = nudgeConfig.type;

                    return component
                        ? React.createElement(component, {
                            config,
                            isLast,
                            type,
                            setIsBtnDisabled
                          })
                        : null;
                }
                return null;
            });
        }
        return [];
        
    }, []);

    return components;
};

export default useConfigComponents;
