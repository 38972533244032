import { IProducts } from "@models/config-groups/common";
import CSS from "./product-list-item.module.scss";
import deleteBtn from "../../../../../assets/icons/delete_btn.svg"

interface IProductListItem{
    productDetail:IProducts,
    handleDelete:Function
}

const ProductListItem:React.FunctionComponent<IProductListItem> = ({productDetail,handleDelete}) =>{
    const handleDeleteItem = (e:any)=> {
        e?.stopPropagation();
        handleDelete(productDetail.value)
    }

    return (
        <div className={CSS['container']}>
            <div className={CSS['product-detail-container']}>
                <img src={productDetail.logo} alt="product-img"></img>
                <p title={productDetail.text}>{productDetail.text.slice(0,20)}...</p>
            </div>
            <div className={CSS['delete-btn-container']} onClick={handleDeleteItem}>
                <img src={deleteBtn} alt="delete-btn"></img>
            </div>
        </div>
    )
}

export default ProductListItem;