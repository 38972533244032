import { Dropdown } from "@gofynd/nitrozen-react";
import { IExcludedProducts, IProducts } from "@models/config-groups/common"
import { useEffect, useState } from "react"
import { getProducts } from "../../../services/nudge-setting.service";
import { useParams } from "react-router-dom";
import { debounce } from "src/utils/helpers";
import CSS from "./excluded-products.module.scss";
import SidePanel from "../../layouts/side-panel/side-panel.component";

interface IExcludedProductsProps{
    config:IExcludedProducts,
    handleOnChange:Function
}

const PAGE_SIZE:number  = 10;

const ExcludedProducts:React.FunctionComponent<IExcludedProductsProps> = ({config,handleOnChange}) =>{
    const [productsListOptions,setProductsListOptions] = useState<Array<IProducts>>([]);
    const [dropDownValue,setDropDownValue] = useState<Array<number>>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [searchValue,setSearchValue] = useState<string>("");
    const [isNextAvailable,setIsNextAvailable] = useState<boolean>(true);
    const [dropdownSelectedProducts,setDropdownSelectedProducts] = useState<Array<IProducts>>([]);
    const [openSidePanel,setOpenSidePanel] = useState(false);
    const { application_id: applicationId } = useParams();

    useEffect(()=>{
        if(config.items){
            const selectedProductsUUD = config.items.map((item)=>{
                return item.value;
            });
            setDropDownValue([...selectedProductsUUD]);
            setDropdownSelectedProducts([...config.items])
        }
    },[]);

    useEffect(()=>{
        if(applicationId){
            (async () => {
                try {
                    const response = await getProducts(applicationId,{
                        pageNumber:pageNumber,
                        pageSize:PAGE_SIZE,
                        search:searchValue
                    });
                    const { data } = response;
                    const products = data.results.items;
                    setIsNextAvailable(data.results.page.has_next);
                    if(pageNumber === 1) setProductsListOptions([...products])
                    else setProductsListOptions((prevState:Array<IProducts>) => [...prevState,...products])
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    },[pageNumber,searchValue,applicationId]);

    useEffect(()=>{
        handleOnChange(dropdownSelectedProducts);
    },[dropdownSelectedProducts]);

    const handleOnScroll = (e:any)=>{
        const parentCoordinates = e?.getBoundingClientRect();
        const childNodesLength = e?.childNodes.length;
        
        if(childNodesLength > 0){
            const middleChild = e?.childNodes[Math.floor(childNodesLength/2)];
            const childPosition = middleChild.getBoundingClientRect();   
            const isChildInViewportOfParent = childPosition.top <= parentCoordinates.bottom ?  true : false;
    
            if(isNextAvailable && isChildInViewportOfParent){
                setPageNumber((prevValue) => prevValue + 1)
            }
        }
    }

    const handleSearchInputChange = (e:any)=>{
        setSearchValue(e.text);
        setPageNumber(1);
    }

    const handleDropdownChange = (productList:any) =>{
        const modifiedList:Array<IProducts> = productsListOptions.filter((item)=>{
            return productList.includes(item.value);
        });
        const prevStateFiltered = dropdownSelectedProducts.filter((item)=>{
            return productList.includes(item.value);
        });

        const uniqueArray = [...new Set([...prevStateFiltered,...modifiedList])];
        setDropdownSelectedProducts((prev:any) => [...uniqueArray]);
        setDropDownValue(productList);
    }
    
    const handleToggleSidePanelClick = () =>{
        setOpenSidePanel((prev)=> !prev);
    }

    const handleDeletedItem = (value:number) =>{
        const filteredProducts:Array<IProducts>= dropdownSelectedProducts.filter((item:IProducts)=>{
            return item.value !== value;
        });
        const filteredDropdownValue:Array<number>= dropDownValue.filter((item:number)=>{
            return item !== value;
        });
        setDropdownSelectedProducts(filteredProducts);
        setDropDownValue(filteredDropdownValue)
    }

    return (
        <div className={CSS['container']}>
            <Dropdown
                label={config.title}
                tooltip={config.tooltip}
                tooltipPosition="top-start"
                items={productsListOptions} // options available / products available
                placeholder={
                    dropdownSelectedProducts.length > 0 
                    ? `${dropdownSelectedProducts.length} Products Selected` 
                    : "Search Excluded Products"
                }
                value={dropDownValue} // Array of "value" that need to be selected
                multiple
                searchable={true}
                onChange={handleDropdownChange}
                onSearchInputChange={debounce(handleSearchInputChange,500)}
                onScroll={debounce(handleOnScroll,500)}
                className={CSS['dropdown-class']}
            />
            {
                dropdownSelectedProducts?.length > 0  && (
                    <div className={CSS['side-panel-toggle']} >
                        <span onClick={handleToggleSidePanelClick}>Show All Selected Products</span>
                    </div>
                )
            }
            {
                dropdownSelectedProducts?.length > 0 && openSidePanel && 
                <SidePanel list={dropdownSelectedProducts} handleDelete={handleDeletedItem} handleClose={handleToggleSidePanelClick}/>
            }
        </div>
    )
}

export default ExcludedProducts