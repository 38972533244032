import { ToggleButton } from "@gofynd/nitrozen-react";
import CSS from "./toggle-ui.module.scss"
import { IToggleUI } from "@models/config-groups/common";
import ToggleAtom from "../../atoms/toggle/toggle";
import { memo } from "react";


interface IToggleUIProps{
    id:string,
    config:IToggleUI,
    handleToggle:() => void
}

const ToggleUI = ({
    id,
    config,
    handleToggle
}:IToggleUIProps) =>{
    
    return (
        <>
            <div className={CSS["container"]}>
                <div className={CSS["description"]}>
                    <p className={CSS["title"]}>{config.title}</p>
                    <p className={CSS["subheading"]}>{config.description}</p>
                </div>
                <ToggleAtom id={id} value={config.enabled} onToggle={handleToggle} />
            </div>
        </>
    )
}

export default memo(ToggleUI);