import { IProducts } from "@models/config-groups/common";
import CSS from "./side-panel.module.scss";
import closeBtn from "../../../../../assets/icons/close_btn.svg";
import ProductListItem from "../../molecules/product-list-item/product-list-item.component";

interface ISidePanel{
    list:Array<IProducts>
    handleDelete:Function
    handleClose:React.MouseEventHandler<HTMLDivElement> | undefined
}

const SidePanel:React.FunctionComponent<ISidePanel> = ({list,handleDelete,handleClose}) =>{
    return (
        <div className={CSS['blurred-background']} >
           <div className={CSS['side-panel']}>
                <div className={CSS['header-container']}>
                    <p className={CSS['heading']}> Excluded Products </p>
                    <div className={CSS['close-btn-container']} onClick={handleClose}>
                        <img src={closeBtn} alt="close-btn"></img>
                    </div>
                </div>
                <div className={CSS['product-list-container']}>
                    <div className={CSS['inner-container']}>
                        <div className={CSS['header']}>
                           <p>Product Name</p> 
                           <p>Action</p>
                        </div>
                        {
                            list.map((item)=>{
                                return <ProductListItem productDetail={item} handleDelete={handleDelete}/>
                            })
                        }
                    </div>
                </div>
           </div>
        </div>
    )
}

export default SidePanel;