export const NUDGE_TYPE :any = {
    PRODUCT_SPECIFIC:"PRODUCT_SPECIFIC",
    ADD_TO_CART:"ADD_TO_CART",
    ORDER:"ORDER",
    PRODUCT_REVIEW:"PRODUCT_REVIEW",
    LOW_STOCK:"LOW_STOCK",
    CART_SUMMARY:"CART_SUMMARY",
    ORDER_SUMMARY:"ORDER_SUMMARY",
    LIVE_VISITORS:"LIVE_VISITORS",
    RECENT_VISITORS:"RECENT_VISITORS",
}