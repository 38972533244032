import {  useContext, useEffect, useRef, useState } from "react";
import { useSearchParams ,useParams} from "react-router-dom";
import CSS from './config-page-header.module.scss'
import { nudgeConfigContext ,IConfigContext} from "../../../../../contexts/context";
import { toggleNudgeActiveStatus } from "src/pages/nudge-settings/services/nudge-setting.service";
import ToggleAtom from "../../atoms/toggle/toggle";
import { showDangerToast, showSuccessToast } from "src/services/toast.service";
import { TOAST_MESSAGES } from "src/constants/toastConstant";

const ConfigPageHeader:React.FunctionComponent = () =>{
    const {
        nudgeConfig :{
            heading,
            description,
            is_active
        },
        setNudgeConfig
    } = useContext(nudgeConfigContext) as IConfigContext;
    
    const { application_id: applicationId } = useParams();
    const [searchParams] = useSearchParams();
    const [isActive,setIsActive] = useState(is_active);
    const nudgeType :string | null= searchParams.get("nudgeType");
    const isFirstRender = useRef(false);
    
    useEffect(()=>{
        if (!isFirstRender.current) {
            isFirstRender.current = true;
            return;
        }
        setNudgeConfig((prevState:any) => 
            prevState ? {
                ...prevState,
                is_active: isActive
            } : undefined
        )

        // UPDATE TO DB
        if (applicationId && nudgeType) {
            (async () =>{
                let toastMsgSuccess = isActive ? TOAST_MESSAGES.nudgeActivated : TOAST_MESSAGES.nudgeDeactivated;
                let toastMsgError = isActive ? TOAST_MESSAGES.nudgeActivatedError : TOAST_MESSAGES.nudgeDeactivatedError;
                try{
                    const response = await toggleNudgeActiveStatus(applicationId,nudgeType,isActive);  
                    if(response.data.code === 200){
                        showSuccessToast(toastMsgSuccess); 
                        return;
                    }
                    showDangerToast(toastMsgError);
                }catch(err){
                    showDangerToast(toastMsgError)
                }
            })();
        }
    },[isActive,applicationId,nudgeType,setNudgeConfig]);

    const handleToggle = () =>{
       setIsActive((prevState)=> !prevState);
    }

    return (
        <div className={CSS["container"]}>
            <div className={CSS["heading-text"]}>
                <h2 className={CSS["heading"]}>{heading}</h2>
                <p className={CSS["description"]}>{description}</p>
            </div>
            <div className={CSS['toggle-btn-container']}>
                <span className={CSS["toggle-text"]}>
                    {
                        isActive ? "Enabled" : "Disabled"
                    }
                </span>
                <ToggleAtom id={"configPageHeader"} value={isActive} onToggle={handleToggle} />
            </div>
        </div>
    )
}

export default ConfigPageHeader;