import { IOrderSummaryCondition } from "@models/config-groups/order-summary-condition-config";
import MinInput from "../../atoms/min-input/min-input.component";
import { Dropdown } from "@gofynd/nitrozen-react";
import HorizontalDiv from "../../../../../components/atoms/horizontal-div/horizontal-div.component";
import CSS from "./order-summary-condition.module.scss";
import { useContext, useEffect, useState } from "react";
import { nudgeConfigContext } from "../../../../../contexts/context";
import { isInputValueWithinRange } from "../../../../../utils/helpers";

interface IOrderSummaryConditionProps{
    config:IOrderSummaryCondition,
    isLast:boolean,
    setIsBtnDisabled:Function
}

const OrderSummaryCondition:React.FunctionComponent<IOrderSummaryConditionProps> = ({config,isLast,setIsBtnDisabled}) =>{
    
    const [orderSummaryCondition,setOrderSummaryCondition] = useState<IOrderSummaryCondition>(config);
    const {setNudgeConfig} = useContext(nudgeConfigContext);

    useEffect(()=>{
        if(isInputValueWithinRange(
            orderSummaryCondition.min_orders.value,
            orderSummaryCondition.min_orders.min,
            orderSummaryCondition.min_orders.max) 
            && 
            isInputValueWithinRange(
                orderSummaryCondition.min_visitors.value,
                orderSummaryCondition.min_visitors.min,
                orderSummaryCondition.min_visitors.max
            )){
                setNudgeConfig((prevState:any) => 
                    prevState ? {
                        ...prevState,
                        configs:{
                            ...prevState.configs,
                            order_summary_condition:orderSummaryCondition
                        }
                    }:undefined
                );
                setIsBtnDisabled(false);   
        }
    },[orderSummaryCondition,setNudgeConfig,setIsBtnDisabled]);
    
    const handleMinVisitorChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setOrderSummaryCondition((prevState:any)=> prevState ? {
            ...prevState,
            min_visitors:{
                ...prevState.min_visitors,
                value:Number(event?.target?.value)
            }
        }:undefined);
    }

    const handleMinOrdersChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setOrderSummaryCondition((prevState:any)=> prevState ? {
            ...prevState,
            min_orders:{
                ...prevState.min_orders,
                value: Number(event?.target?.value)
            }
        }:undefined);
    }

    const handleTimePeriodChange = (value:string) => {
        setOrderSummaryCondition((prevState:any) => prevState ? {
            ...prevState,
            time_period:{
                ...prevState.time_period,
                value:Number(value)
            }
        }:undefined)
    }

    return (
        <>
            <div className={CSS['container']}>
                <div className={CSS['grid-of-two']}>
                    <MinInput 
                        config={orderSummaryCondition.min_visitors} 
                        handleOnChange={handleMinVisitorChange}
                        setIsBtnDisabled={setIsBtnDisabled}
                    />
                    <MinInput 
                        config={orderSummaryCondition.min_orders} 
                        handleOnChange={handleMinOrdersChange}
                        setIsBtnDisabled={setIsBtnDisabled}
                    />
                </div>
                <div>
                    <Dropdown 
                        items={orderSummaryCondition.time_period.items}
                        label={orderSummaryCondition.time_period.title}
                        onChange={handleTimePeriodChange}
                        tooltip={orderSummaryCondition.time_period.tooltip}
                        tooltipPosition="top-start"
                        value={orderSummaryCondition.time_period.value}
                    />
                </div>
            </div>
            {
                isLast ? <></> : <HorizontalDiv></HorizontalDiv>
            }
        </>
    )
}

export default OrderSummaryCondition;