import React, { useState } from "react";
import { Dropdown, SvgIcChevronLeft, SvgIcChevronRight } from "@gofynd/nitrozen-react";
import CSS from "./pagination.module.scss"

export  interface IPaginationData {
    limit: number;
    total: number;
    current: number;
}
export interface IPaginationComponentProps {
  id: string;
  value:IPaginationData,
  pageSizeOptions?: number[]
  handlePaginationChange: (page: IPaginationComponentProps["value"]) => unknown;
  mode: undefined;
  name?: string;
  setCurrentPage:Function;
}

function incrementPage(isLastPage: boolean, previousPageValue: number) {
  return isLastPage ? previousPageValue : previousPageValue + 1
}

function decreasePage(previousPageValue: number) {
  return previousPageValue > 1 ? previousPageValue - 1 : previousPageValue
}

function createPageSizeOptions(sizes: number[]) {
  return sizes.map(size => ({
    text: size.toString(),
    value: size
  }))
}

const PageSizes = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 }
]

const Pagination: React.FC<IPaginationComponentProps>= ({
  value,
  handlePaginationChange,
  id = 'pagination',
  name = 'Items',
  pageSizeOptions,
  setCurrentPage
}: IPaginationComponentProps): JSX.Element => {
  const { limit, total, current } = value;
  /**
   * `itemsPerPage` should be greater than 0
   */
  const pageSize = limit > 0 ? limit : 10
  const [itemsPerPage, setItemsPerPage] = useState<number>(pageSize);
  // const [currentPage, setCurrentPage] = useState<number>(current ?? 1);
  const firstItemPosition = pageSize * (current - 1) + 1;
  const isLastPage = total <= (pageSize * current);
  const lastItemPosition = isLastPage ? total : (pageSize * current);

  const emitChange = (size: number, currentPageNo: number) => {
    handlePaginationChange({
      limit: size,
      total,
      current: currentPageNo,
    })
  }

  const handlePageSizeChange = (size: number) => {
    emitChange(size, 1);
    setCurrentPage(1);
    setItemsPerPage(size);
  }

  const goForward = () => {
    emitChange(itemsPerPage, incrementPage(isLastPage, current));
    // setCurrentPage((previousValue:any) => incrementPage(isLastPage, previousValue));
  }

  const goBack = () => {
    emitChange(itemsPerPage, decreasePage(current));
    // setCurrentPage((previousValue:any) => decreasePage(previousValue));
  }

  return (
    <div id={id} className={CSS.paginator}>
      <div className={CSS.text}>Result {firstItemPosition} - {lastItemPosition} of {total}</div>
      <div className={CSS.controls}>
        <span className={CSS["rows-per-page-text"]}>{name} per page</span>
        <Dropdown
          id={id + 'itemsPerPage'}
          value={itemsPerPage}
          onChange={handlePageSizeChange}
          className={CSS.dropdown}
          items={pageSizeOptions
            ? createPageSizeOptions(pageSizeOptions)
            : PageSizes}
        />

        <div
          className={`${CSS['button']} ${current === 1 ? CSS['buttonDisabled'] : ''}`}
          onClick={goBack}
          role="button"
        >
          <SvgIcChevronLeft size={24} color="#8F8F8F" />
        </div>
        <div
          className={`${CSS['button']} ${isLastPage ? CSS['buttonDisabled'] : ''}`}
          onClick={goForward}
          role="button"
        >
          <SvgIcChevronRight size={24} color="#8F8F8F" />
        </div>

      </div>
    </div>
  )
}

export default Pagination;