import {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import CSS from "./live-preview.module.scss";
import { ConfigMessage } from "../../../utils/message";
import LaptopIcon from "../../../assets/icons/laptop_icon.svg";
import PhoneIcon from "../../../assets/icons/phone_icon.svg";
import LaptopBrowserControls from "../../../assets/images/browser_controls_laptop.svg";
import PhoneBrowserControls from "../../../assets/images/browser_controls_phone.svg";
import { INudgeConfig } from "src/pages/nudge-settings/nudge-setting.types";
import { INudgeSettings } from "src/pages/general-settings/general-settings.types";

interface IDataToSend{
  nudgeConfig?:INudgeConfig,
  generalConfig:INudgeSettings
}

interface ILivePreviewProps {
  dataToSend: IDataToSend;
}

const LivePreview: React.FunctionComponent<ILivePreviewProps> = ({
  dataToSend
}) => {
  const [isMobile,setIsMobile] = useState<boolean>(false);
  const [iframeReady, setIframeReady] = useState<boolean>(false);
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const handleIframeReady = useCallback((event: MessageEvent) => {
    if (event.data.auth === "cHJldmlldw==" && event.data.data === "ready") {
      setIframeReady(true);
    }
  }, [setIframeReady]);

  const sendData = (data: any) => {
    iFrameRef.current?.contentWindow?.postMessage(
      new ConfigMessage(data),
      window.location.origin
    );
  }

  useEffect(() => {
    window.addEventListener("message", handleIframeReady);
    return () => {
      window.removeEventListener("message", handleIframeReady);
    };
  }, [handleIframeReady]);

  useEffect(() => {
    if (!iframeReady) {
      return;
    }
    sendData({
      ...dataToSend,
      isMobile:isMobile,
      mode:"PREVIEW"
    })
  }, [iframeReady, dataToSend,isMobile]);

  return (
    <div className={CSS.preview}>
      <div className={CSS.preview_header}>
        <div className={CSS.flex}>
          <div
            className={`${CSS['preview_icon']} ${CSS['laptop_icon']} ${isMobile ? "" : CSS['selected_icon']}`}
            onClick={() => setIsMobile(false)}
          >
            <img src={LaptopIcon} alt="laptop-icon" />
          </div>
          <div
            className={`${CSS['preview_icon']} ${CSS['phone_icon']} ${isMobile ? CSS['selected_icon'] : ""}`}
            onClick={() => setIsMobile(true)}
          >
            <img src={PhoneIcon} alt="phone-icon" />
          </div>
        </div>
        <div className={CSS.preview_heading}>Live Preview</div>
      </div>
      <div
        className={`${CSS['main_wrapper']} ${isMobile ? CSS['phone'] : CSS['laptop']}`}
      >
        <div className={CSS.image}>
            <img
              src={
                isMobile
                ? PhoneBrowserControls
                : LaptopBrowserControls
              }
              className=""
              alt="browser-controls"
            />
          </div>
          <iframe
            src="/preview"
            ref={iFrameRef}
            title="preview"
            className={CSS.iframe}>
          </iframe>
      </div>
    </div>
  );
};

export default LivePreview;
  