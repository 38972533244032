import React from "react";
import CSS from "./page-wrapper.module.scss";
import {
  BreadCrumb,
  overWriteBreadCrumpsItems,
  setBreadCrumpsItems,
} from "nitrozen-react-extension";
import { useLocation, useNavigate } from "react-router-dom";

interface IBreadCrumbItem {
  label: string;
  link: string;
}

interface PageWrapperComponentProps {
  breadCrumb?: {
    showBreadCrumb: boolean;
    label: string;
  };
  children: JSX.Element;
}
export default function PageWrapper({
  breadCrumb,
  children,
}: PageWrapperComponentProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = (link: string) => {
    navigate(link);
  };

  if (breadCrumb) {
    let storageBreadCrumbs: IBreadCrumbItem[];
    try {
      storageBreadCrumbs = JSON.parse(
        window.localStorage.getItem("breadcrumbs") || "[]"
      ) as IBreadCrumbItem[];
    } catch (e) {
      storageBreadCrumbs = [];
      console.error(e);
    }
    if (Array.isArray(storageBreadCrumbs) && storageBreadCrumbs.length) {
      window.localStorage.setItem("breadcrumbs", "[]");
      overWriteBreadCrumpsItems(storageBreadCrumbs);
    } else {
      setBreadCrumpsItems(
        breadCrumb.label,
        location.pathname + location.search
      );
    }
  }
  return (
    <div>
      {breadCrumb && breadCrumb.showBreadCrumb && (
        <BreadCrumb handleClick={handleClick} />
      )}
      <div
        className={`${CSS.page}  ${
          breadCrumb && breadCrumb.showBreadCrumb ? " mt-24" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}
