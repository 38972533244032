import React, { useContext, useState } from "react";

import PositionsBody from "./components/molecules/position-body/positions-body.component";
import Header from "src/pages/general-settings/components/molecules/header/header.component";
import { IContext, generalSettingContext } from "src/contexts/context";

const Positions: React.FunctionComponent = () => {
  const {
    generalSettings: {
      general_settings: { positions },
    },
  } = useContext(generalSettingContext) as IContext;

  const [collapse, setCollapse] = useState(true);
  const handleCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <div className="positions">
      <Header
        title={positions.name}
        description={positions.description}
        collapse={collapse}
        handleClick={handleCollapse}
      />
      {!collapse && <PositionsBody />}
    </div>
  );
};

export default Positions;
