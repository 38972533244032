import { ISelectedPages } from "@models/config-groups/selected-pages-config"
import HorizontalDiv from "../../../../../components/atoms/horizontal-div/horizontal-div.component";
import CSS from './selected-pages.module.scss';
import { useContext, useEffect, useState } from "react";
import { Dropdown } from "@gofynd/nitrozen-react";
import salesChannelPagesService from "src/services/saleschannel-pages.service";
import { ApplicationPage } from "@models/application-page";
import { nudgeConfigContext } from "src/contexts/context";
import { NUDGE_TYPE } from "src/constants/globalConstants";

interface ISelectedPagesProps{
    config:ISelectedPages,
    isLast:boolean,
    type:string
}

interface IPageOption{
    text: string;
    value: string;
}


const SelectedPages:React.FunctionComponent<ISelectedPagesProps> = ({config,isLast,type})=>{
    const [selectedPageConfig,setSelectedPageConfig] = useState<ISelectedPages>(config);
    const [pageOptions, setPageOptions] = useState<IPageOption[]>([]);
    const [selectedPages, setSelectedPages] = useState<string[]>();
    

    const [dropdownSelectedPage, setDropdownSelectedPage] = useState<ApplicationPage[]>([]);
    const {setNudgeConfig} = useContext(nudgeConfigContext);

    const allPages = (type === NUDGE_TYPE.PRODUCT_SPECIFIC || type === NUDGE_TYPE.LOW_STOCK ) ? [{display: 'Product Description',page_type:'product-description'}] : salesChannelPagesService?.getPages() ?? [];
    // const allPages = salesChannelPagesService?.getPages() ?? [];

    useEffect(() => {
        const pages = selectedPageConfig?.pages?.map((page:any) => page?.display);
        setSelectedPages(pages);
    }, [selectedPageConfig?.pages]);

    useEffect(() => {
        handleSetPageOptions();
    }, []);

    const handleSetPageOptions = () => {
        const pageOptions = allPages?.map((page) => ({
            text: page?.display + " Page",
            value: page?.display,
        }));
        setPageOptions(pageOptions);
    };
    
    useEffect(()=>{
        setNudgeConfig((prevState:any) => 
            prevState ? {
                ...prevState,
                configs:{
                    ...prevState.configs,
                    selected_pages:selectedPageConfig
                }
            }:undefined
        )    
    },[selectedPageConfig,setNudgeConfig]);

    useEffect(()=>{
        setSelectedPageConfig((prevState) => ({
            ...prevState,
            pages: dropdownSelectedPage,
        }));
    },[dropdownSelectedPage]);


    const handlePageListChange = (pageList: string[]) => {

        const newPageList = allPages?.filter((page) => {
            return pageList?.includes(page?.display);
        });
        setDropdownSelectedPage(newPageList);
        };

    return (
        <>
            <div className={CSS["container"]}>
                <p className={CSS["title"]}>{selectedPageConfig.title}</p>
                <p className={CSS["desc"]}>{selectedPageConfig.description}</p>
                <div className={CSS["dropdown-container"]}>
                    <Dropdown
                        items={pageOptions}
                        onChange={handlePageListChange}
                        placeholder="Select Page"
                        value={selectedPages}
                        enableSelectAll
                        multiple
                        className={CSS['dropdown-class']}
                    />
                </div>
            </div>
            {
                isLast ? <></> : <HorizontalDiv></HorizontalDiv>
            }
        </>
    )
}

export default SelectedPages