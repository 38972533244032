import { useContext, useEffect, useState } from "react";
import { generalSettingContext, nudgeConfigContext } from "src/contexts/context";
import LivePreview from "src/components/molecules/live-preview/live-preview.component";
import { INudgeConfig } from 'src/pages/nudge-settings/nudge-setting.types';
import { INudgeSettings } from 'src/pages/general-settings/general-settings.types';
import CSS from "./nudge-config-right-panel.module.scss"
interface IDataToSend{
    nudgeConfig:INudgeConfig,
    generalConfig:INudgeSettings
}

const NudgeConfigRightPanel = () =>{
    const { nudgeConfig } =  useContext(nudgeConfigContext);
    const { generalSettings } = useContext(generalSettingContext);
    const [ dataToSend , setDataToSend] = useState<IDataToSend>({} as IDataToSend);

    useEffect(()=>{
        setDataToSend({
            nudgeConfig:nudgeConfig,
            generalConfig:generalSettings
        })
    },[nudgeConfig,generalSettings]);

    return (
        <div className={CSS['container']}>
            <LivePreview dataToSend={dataToSend}/>
        </div>
    )
}

export default NudgeConfigRightPanel;