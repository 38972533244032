import ToggleUI from "../toggle-ui/toggle-ui.component";
import { useContext, useEffect, useState } from "react";
import { nudgeConfigContext } from "../../../../../contexts/context";
import HorizontalDiv from "../../../../../components/atoms/horizontal-div/horizontal-div.component";
import ReplaceByWord from "../replay-by-word/replace-by-word.component";
import { IAnonymous } from "@models/config-groups/anonymous-config";

interface IAnonymousProps{
    config:IAnonymous,
    isLast:boolean,
    setIsBtnDisabled:Function
}

const Anonymous:React.FunctionComponent<IAnonymousProps> = ({config,isLast,setIsBtnDisabled}) => {
    
    const [anonymous,setAnonymous] = useState<IAnonymous>(config);
    const {setNudgeConfig} = useContext(nudgeConfigContext);
    
    useEffect(()=>{
        setNudgeConfig((prevState:any) => 
            prevState ? {
                ...prevState,
                configs:{
                    ...prevState.configs,
                    anonymous:anonymous
                }
            }:undefined
        )    
        if(anonymous.replace_by_word.value.length < anonymous.replace_by_word.min_char){
            setIsBtnDisabled(true);
        }else{
            setIsBtnDisabled(false);
        }
    },[anonymous,setNudgeConfig]);

    const handleAnonymousToggle = () =>{
        setAnonymous((prevState:any) => prevState 
            ? {
                ...prevState,
                enabled:!prevState.enabled
            } : undefined
        )
    }  

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>{
        setAnonymous((prevState:any) => prevState 
            ?{
                ...prevState,
                replace_by_word:{
                    ...prevState.replace_by_word,
                    value:event?.target?.value
                }
            }: undefined )
    }

    return (
       <>
        <ToggleUI id={"anonymous"} config={anonymous} handleToggle={handleAnonymousToggle}/>
        {
            anonymous.enabled ? <ReplaceByWord config={anonymous} handleChange={handleInputChange}/> : <></>
        }
        {
            isLast ? <></> : <HorizontalDiv></HorizontalDiv>
        }
       </>
    )
}

export default Anonymous;