import React from "react";
import { INudgeCategory } from "@models/nudge-category";
import HighlightColor from "../components/molecules/highlight-color/highlight-color.component";
import HeaderTag from "../components/molecules/header-tag/header-tag.component";
import NotificationTime from "../components/molecules/notification-time/notification-time.component";
import Anonymous from "../components/molecules/anonymous/anonymous.component";
import CartSummaryCondition from "../components/molecules/cart-summary-condition/cart-summary-condition.component";
import OrderSummaryCondition from "../components/molecules/order-summary-condition/order-summary-condition.component";
import RecentVisitorCondition from "../components/molecules/recent-visitor-condition/recent-visitor-condition.component";
import LiveVisitorCondition from "../components/molecules/live-visitor-condition/live-visitor-condition.component";
import ProductReviewCondition from "../components/molecules/product-review-condition/product-review-condition.component";
import SelectedPages from "../components/molecules/selected-pages/selected-pages.component";
import LowStockCondition from "../components/molecules/low-stock-condition/low-stock-condition.component";

const COMPONENT_TYPE_VS_CONFIG_COMPONENT : Record<keyof INudgeCategory["configs"], React.FunctionComponent<any>> = {
    header_tag:HeaderTag,
    notification_time:NotificationTime,
    anonymous:Anonymous,
    selected_pages:SelectedPages,
    highlight_color:HighlightColor,
    cart_summary_condition:CartSummaryCondition,
    order_summary_condition:OrderSummaryCondition,
    recent_visitor_condition:RecentVisitorCondition,
    live_visitor_condition:LiveVisitorCondition,
    product_review_condition:ProductReviewCondition,
    low_stock_condition:LowStockCondition
}

class ConfigComponentFactory { 
    getComponent(componentType: keyof INudgeCategory["configs"]) {
        return COMPONENT_TYPE_VS_CONFIG_COMPONENT[componentType]
    }
}

const configComponentFactory = new ConfigComponentFactory();

export default configComponentFactory;
