import { IProductReviewCondition } from "@models/config-groups/product-review-condition-config"
import { useContext, useEffect, useState } from "react";
import MinInput from "../../atoms/min-input/min-input.component";
import { nudgeConfigContext } from "../../../../../contexts/context";
import CSS from "./product-review-condition.module.scss"
import HorizontalDiv from "../../../../../components/atoms/horizontal-div/horizontal-div.component";
import { isInputValueWithinRange } from "../../../../../utils/helpers";

interface IProductReviewConditionProps{
    config:IProductReviewCondition,
    isLast:boolean,
    setIsBtnDisabled:Function
}

const ProductReviewCondition:React.FunctionComponent<IProductReviewConditionProps> = ({config,isLast,setIsBtnDisabled}) =>{
    const [productReviewCondition,setProductReviewCondition] = useState<IProductReviewCondition>(config);
    const {setNudgeConfig} = useContext(nudgeConfigContext);

    useEffect(()=>{
        if(isInputValueWithinRange(
            productReviewCondition.min_stars.value,
            productReviewCondition.min_stars.min,
            productReviewCondition.min_stars.max)
        ){
            setNudgeConfig((prevState:any) => 
                prevState ? {
                    ...prevState,
                    configs:{
                        ...prevState.configs,
                        product_review_condition:productReviewCondition
                    }
                }:undefined
            );
            setIsBtnDisabled(false);
        }  
    },[productReviewCondition,setNudgeConfig,setIsBtnDisabled]);

    const handleMinStarsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setProductReviewCondition((prevState:any)=> prevState ? {
            ...prevState,
            min_stars:{
                ...prevState.min_stars,
                value:Number(event?.target?.value)
            }
        }:undefined);
    }

    return (
        <>
            <div className={CSS['container']}>
                <MinInput 
                    config={productReviewCondition.min_stars} 
                    handleOnChange={handleMinStarsChange} 
                    setIsBtnDisabled={setIsBtnDisabled}
                />
            </div>
            {
                isLast ? <></> : <HorizontalDiv></HorizontalDiv>
            }
        </>
    )
}

export default ProductReviewCondition