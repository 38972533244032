export const TOP_CENTER = "top-center";

export const TOAST_TYPE = {
    success: "success",
    danger: "danger",
};

export const TOAST_MESSAGES = {
    extensionEnabled: "Extension Enabled",
    extensionDisabled: "Extension Disabled",
    extensionEnabledError: "Unable to Enable Extension",
    extensionDisabledError: "Unable to Disable Extension",
    nudgeActivated:"Nudge Activated Successfully",
    nudgeDeactivated:"Nudge Deactivated Successfully",
    nudgeActivatedError:"Unable to Activate Nudge",
    nudgeDeactivatedError:"Unable to Deactivate Nudge",
    nudgeUpdateSuccess:"Nudge updated successfully",
    nudgeUpdateError:"Failed to update the nudge",
    nudgeDeletedSuccess:"Nudge Deleted Successfully",
    nudgeDeletedError:"Failed to delete the nudge",
    subscriptionError:"ERROR WHILE FETCHING SUBSCRIPTION DETAILS"
};
